import { useContext, useEffect } from "react";
import { ThemeContext } from "../components/providers/ThemeProvider";
import { useUserPreferencesContext } from "@ncr-voyix-commerce/react-common-components";
import useMediaQuery from '@mui/material/useMediaQuery';

export const useConfigureUserPreferences = () => {
  const { userPreferences } = useUserPreferencesContext();
  const { setColorMode } = useContext(ThemeContext);
  const systemPrefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");


  useEffect(() => {
    if (userPreferences.theme === 'system') {
      setColorMode(systemPrefersDarkMode ? 'dark' : 'light');
    } else {
      setColorMode(userPreferences.theme);
    }
  }, [userPreferences.theme, setColorMode]);
};
