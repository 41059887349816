import { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import { Box, DialogContent, DialogTitle, Link, Stack, Typography, useTheme } from '@mui/material';
import useMobileView from 'src/hooks/useMobileView';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import PasswordValidationList, {
    PasswordMatchesMessage,
    PasswordStrengthBar,
    PasswordValidity,
} from 'src/components/user-settings/security/credentials/LoginCredentials/ResetPassword/Password/PasswordValidationList';
import { updateUserPassword } from 'src/fetchers/user-profile.fetchers';
import { AxiosError } from 'axios';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import { ThemeValues } from 'src/constants/theme-values';
import { Global } from 'src/constants/global';
import PasswordTextField from 'src/components/user-settings/security/credentials/LoginCredentials/ResetPassword/Password/PasswordTextField';
import { CommonDialog } from 'src/components/common/Dialog/CommonDialog';
import { useSnackbarContext } from 'src/components/common/SnackBar/SnackBar';

const ResetPasswordModal = ({
    firstName,
    lastName,
    onClose,
}: {
    firstName: string;
    lastName: string;
    onClose: () => void;
}) => {
    const { t } = useTranslationNs({ keyPrefix: 'USER_SETTINGS.SECURITY.PASSWORD_RESET' });
    const isMobileView = useMobileView();
    const theme = useTheme();

    const [currTouched, setCurrTouched] = useState<boolean>(false);
    const [touched, setTouched] = useState<boolean>(false);
    const [confirmTouched, setConfirmTouched] = useState<boolean>(false);
    const [validCurr, setValidCurr] = useState<boolean>(false);
    const [loadingButton, setLoadingButton] = useState(false);

    const [currentPassword, setCurrentPassword] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [confirmedPassword, setConfirmedPassword] = useState<string>('');

    const [validity, setValidity] = useState<PasswordValidity>(
        PasswordValidity.validatePassword(password, confirmedPassword, firstName, lastName),
    );

    const { setSnackOptions } = useSnackbarContext();

    useEffect(() => {
        setValidity(
            PasswordValidity.validatePassword(password, confirmedPassword, firstName, lastName),
        );
    }, [password, currentPassword, confirmedPassword, firstName, lastName]);

    const handleSuccess = () => {
        setSnackOptions({
            open: true,
            color: 'success',
            message: t('SUCCESS').toString(),
        });
        onClose();
    };

    async function onSaveClick() {
        setLoadingButton(true);

        updateUserPassword({
            oldPassword: currentPassword,
            newPassword: password,
        })
            .then(handleSuccess)
            .catch((err: AxiosError) => {
                if (err.message) {
                    let message = '';
                    if (err.message.includes('Password has been used too recently')) {
                        message = t('ERROR.RECENT_PASSWORD').toString();
                    } else if (err.message.includes('Old Password is not correct')) {
                        message = t('ERROR.CURR_PASSWORD').toString();
                    } else if (err.message.includes('Password cannot be your current password')) {
                        message = t('ERROR.SAME_CURR_AND_NEW_PASSWORD').toString();
                    }
                    setSnackOptions({
                        open: true,
                        color: 'error',
                        message: message,
                    });
                } else {
                    setSnackOptions({
                        open: true,
                        color: 'error',
                        message: t('ERROR.FAILED').toString(),
                    });
                }
            })
            .finally(() => {
                setLoadingButton(false);
            });
    }

    async function onLeave() {
        onClose();
    }

    useEffect(() => {
        setValidCurr(currentPassword.length > 0);
    }, [currentPassword]);

    return (
        <CommonDialog
            open
            onClose={onLeave}
            PaperProps={{ sx: { borderRadius: ThemeValues.DialogBorderRadius } }}
            maxWidth={false}
        >
            <DialogTitle>
                <Box
                    display='flex'
                    flexDirection='row'
                    justifyContent='flex-start'
                    alignItems='center'
                    gap='16px'
                >
                    <Typography variant='h5' fontWeight='600'>
                        {t('HEADING')}
                    </Typography>
                    {!isMobileView && (
                        <Link
                            href={`${Global.IdmUrl}/knowledge-base/password-policies`}
                            target='_blank'
                            style={{
                                textDecoration: 'none',
                                fontSize: 13,
                            }}
                        >
                            {t('POLICY')}
                        </Link>
                    )}
                </Box>
            </DialogTitle>
            <DialogContent
                sx={{
                    width: isMobileView ? ThemeValues.MobileDialogWidth : 'auto',
                }}
            >
                <Stack gap='16px'>
                    <Grid container spacing={2} direction='row'>
                        <Grid
                            item
                            sx={{
                                [theme.breakpoints.up(ThemeValues.Breakpoints.sm)]: {
                                    maxWidth: '66.67%',
                                    flexBasis: '66.67%',
                                },
                                [theme.breakpoints.up(ThemeValues.Breakpoints.xs)]: {
                                    maxWidth: '100%',
                                    flexBasis: '100%',
                                },
                            }}
                        >
                            <Stack gap='16px' mt={2}>
                                {setCurrentPassword && (
                                    <PasswordTextField
                                        label={t('CURRENT_PASSWORD')}
                                        error={!validCurr && currTouched}
                                        setValue={setCurrentPassword}
                                        setTouched={setCurrTouched}
                                    />
                                )}
                                <PasswordTextField
                                    value={password}
                                    label={t('NEW_PASSWORD')}
                                    error={!validity.isValid() && touched}
                                    setValue={setPassword}
                                    setTouched={setTouched}
                                />
                                {isMobileView && touched && (
                                    <PasswordStrengthBar passwordValid={validity} />
                                )}
                                <PasswordTextField
                                    value={confirmedPassword}
                                    label={t('CONFIRM')}
                                    error={!validity.match && confirmTouched}
                                    setValue={setConfirmedPassword}
                                    setTouched={setConfirmTouched}
                                />
                                {isMobileView && confirmTouched && (
                                    <PasswordMatchesMessage passwordValid={validity} />
                                )}
                            </Stack>
                        </Grid>
                        {!isMobileView && (
                            <Grid
                                item
                                sx={{
                                    [theme.breakpoints.up(ThemeValues.Breakpoints.sm)]: {
                                        maxWidth: '33.33%',
                                        flexBasis: '33.33%',
                                    },
                                    [theme.breakpoints.up(ThemeValues.Breakpoints.xs)]: {
                                        maxWidth: '100%',
                                        flexBasis: '100%',
                                    },
                                }}
                            >
                                <PasswordValidationList passwordValid={validity} />
                            </Grid>
                        )}
                    </Grid>
                    <LoadingButton
                        variant='contained'
                        fullWidth
                        onClick={onSaveClick}
                        disabled={!validity.isValid() || !validity.matches() || !validCurr}
                        loading={loadingButton}
                        sx={{
                            textTransform: 'unset',
                        }}
                    >
                        {t('SUBMIT')}
                    </LoadingButton>
                </Stack>
            </DialogContent>
        </CommonDialog>
    );
};

export default ResetPasswordModal;
