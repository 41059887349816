import { Box, Link, List, Typography } from '@mui/material';
import { Fragment } from 'react';
import { DialogListItemActionButton } from 'src/components/common/Dialog/DialogListItemActionButton';
import { useOrganizationContext } from 'src/contexts';
import { useApplicationsContextInternal } from 'src/contexts/ApplicationsContext';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { getContextRedirectUrl } from 'src/utils/navigation.utils';
import { ApplicationSwitcherIcon } from '../ApplicationSwitcher/ApplicationSwitcherIcon';
import { ApplicationSwitcherOption } from '../ApplicationSwitcher/ApplicationSwitcherOption';

export const ApplicationAllAppsList = ({ onAppClick }: { onAppClick: () => void }) => {
    const { t } = useTranslationNs();
    const { getSwitcherList, application } = useApplicationsContextInternal();
    const { organization } = useOrganizationContext();
    const applicationsList = getSwitcherList();

    if (applicationsList.length === 0) {
        return (
            <Box sx={{ px: '24px' }}>
                <Typography variant='body1'>
                    {t('APPLICATION_CONTEXT.DIALOG.NO_RESULTS')}
                </Typography>
            </Box>
        );
    }

    return (
        <List>
            {applicationsList.map((app) => {
                const appName = app.name;

                return (
                    <Fragment key={appName}>
                        <DialogListItemActionButton
                            buttonProps={{
                                href: getContextRedirectUrl(
                                    organization?.organizationName,
                                    app.baseUrl || app.redirectUrl,
                                ),
                                target: '_blank',
                                component: Link,
                            }}
                            selected={appName === application?.name}
                            onListItemButtonClick={onAppClick}
                        >
                            <ApplicationSwitcherOption app={app} />
                        </DialogListItemActionButton>
                    </Fragment>
                );
            })}
        </List>
    );
};
