import { Stack, SxProps, Typography } from '@mui/material';
import { overflowTextSx } from 'src/constants/common-sx';
import { ApplicationListItemModel } from 'src/types/application.type';
import { ApplicationSwitcherIcon } from './ApplicationSwitcherIcon';

export const ApplicationSwitcherOption = ({
    app,
    sx = {},
}: {
    app: ApplicationListItemModel;
    sx?: SxProps;
}) => {
    const appDisplayName = app.displayName || app.name;
    return (
        <Stack alignItems='center' sx={sx} width='100%' columnGap='8px' direction='row'>
            <ApplicationSwitcherIcon imageUrl={app?.imageUrl} appName={appDisplayName} />
            <Typography variant='body1' sx={overflowTextSx}>
                {appDisplayName}
            </Typography>
        </Stack>
    );
};
