import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';
import { Global } from '../constants/global';
import { AppConstants } from 'src/constants/app';
import { logger } from 'src/clients/logger';
import { getCookie } from 'src/utils/cookie.utils';

const authClient = axios.create();

authClient.interceptors.request.use(
    (r: InternalAxiosRequestConfig) => {
        if (Global.AccessToken) r.headers.Authorization = `AccessToken ${Global.AccessToken}`;
        if (Global.NepOrganization && r.headers[AppConstants.OrgHeader] === undefined)
            r.headers[AppConstants.OrgHeader] = Global.NepOrganization;
        r.withCredentials = true;

        let { Authorization, ...nonSensitiveHeaders } = r.headers;
        logger.info({
            request: {
                url: r.url,
                method: r.method,
                headers: { Authorization: 'AccessToken REDACTED', ...nonSensitiveHeaders },
                data: r.data,
            },
        });

        return r;
    },
    null,
    { synchronous: true },
);

authClient.interceptors.response.use(
    (response) => {
        logger.info({
            response: {
                url: response.config.url,
                method: response.config.method,
                status: response.status,
            },
        });

        return response;
    },
    (error: AxiosError) => {
        logger.error({
            response: {
                url: error.config.url,
                method: error.config.method,
                message: error.message,
                status: error.response?.status,
                data: error.response?.data,
                headers: error.response?.headers,
            },
        });

        return Promise.reject(error);
    },
);

export default authClient;
