import { Box } from '@mui/material';
import { AppPlaceholder } from 'src/components/common/Icons/AppPlaceholder';
import { useTranslationNs } from 'src/hooks/useTranslationNs';

export const ApplicationSwitcherIcon = ({
    imageUrl,
    appName,
}: {
    imageUrl: string | undefined;
    appName: string | undefined;
}) => {
    const { t } = useTranslationNs();
    const altText = appName ? t('APPLICATION_CONTEXT.SWITCHER.ICON_ALT', { appName }) : '';
    return (
        <Box
            flexShrink={0}
            border='1px solid'
            borderColor='divider'
            borderRadius='6px'
            padding='2px'
            height='32px'
            width='32px'
        >
            {!!imageUrl ? (
                <img
                    alt={altText}
                    src={imageUrl}
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                    }}
                />
            ) : (
                <AppPlaceholder
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                />
            )}
        </Box>
    );
};
