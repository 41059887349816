import { Box, BoxProps, Theme, useTheme } from '@mui/material';

/*
 * To size this component properly, adjust the 'width' prop only.
 * This helps maintain aspect ratio that is set by the 'paddingTop' style.
 */
export const NcrVoyixLogo = (props: BoxProps) => {
    const theme: Theme = useTheme();
    const mobileSxOverrides = {
        '& svg path': {
            fill: theme.palette.text.primary,
        },
    };

    return (
        <Box {...props}>
            <Box
                sx={theme.palette.mode === 'dark' ? mobileSxOverrides : {}}
                width='100%'
                position='relative'
                paddingTop='14.225%'
            >
                <svg
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                    }}
                    width='703'
                    height='100'
                    viewBox='0 0 703 100'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                >
                    <g clipPath='url(#clip0_172_14979)'>
                        <path
                            d='M19.82 43.7693V97.0393H0V1.56934H0.38L61.24 56.2393V2.95934H81.19V98.4393H80.81L19.82 43.7693Z'
                            fill='black'
                        />
                        <path
                            d='M97.1992 49.9996C97.1992 21.3896 118.289 1.30957 148.019 1.30957C157.419 1.30957 164.919 2.95957 171.269 5.88957V25.0896C165.169 21.7796 157.289 20.0096 148.019 20.0096C128.709 20.0096 117.399 32.0896 117.399 50.0096C117.399 67.9296 128.709 80.0096 148.019 80.0096C157.289 80.0096 165.169 78.2296 171.269 74.9196V93.9896C164.919 97.0396 157.419 98.6896 148.019 98.6896C118.289 98.6896 97.1992 78.4796 97.1992 49.9996Z'
                            fill='black'
                        />
                        <path
                            d='M190.131 2.95996H220.751C244.001 2.95996 256.831 15.93 256.831 35.12C256.831 47.33 251.111 57.24 238.661 61.94L259.751 97.03H237.261L209.941 49.99H221.121C230.901 49.99 236.751 44.01 236.751 35.12C236.751 26.23 230.651 20.37 221.001 20.37H209.951V97.03H190.131V2.95996Z'
                            fill='black'
                        />
                        <path
                            d='M286.82 2.95996H308.67L332.18 55.34L355.56 2.95996H377.41L332.31 98.56H331.8L286.82 2.95996Z'
                            fill='black'
                        />
                        <path
                            d='M512.18 60.05L479.02 2.95996H501.64L522.1 39.7L542.56 2.95996H565.18L532.02 60.04V97.04H512.2V60.04L512.18 60.05Z'
                            fill='black'
                        />
                        <path d='M580.09 2.95996H599.91V97.04H580.09V2.95996Z' fill='black' />
                        <path
                            d='M657.91 64.1197L636.95 97.0497H613.32L645.59 48.9897L614.72 2.96973H638.35L658.04 33.7397L677.73 2.96973H701.36L670.36 48.8697L702.76 97.0497H679.13L657.91 64.1197Z'
                            fill='black'
                        />
                        <path
                            d='M445.799 24.4996C452.382 24.4996 457.719 19.1584 457.719 12.5696C457.719 5.98089 452.382 0.639648 445.799 0.639648C439.216 0.639648 433.879 5.98089 433.879 12.5696C433.879 19.1584 439.216 24.4996 445.799 24.4996Z'
                            fill='#5F249F'
                        />
                        <path
                            d='M413 99.36C419.583 99.36 424.92 94.0188 424.92 87.43C424.92 80.8412 419.583 75.5 413 75.5C406.417 75.5 401.08 80.8412 401.08 87.43C401.08 94.0188 406.417 99.36 413 99.36Z'
                            fill='#5F249F'
                        />
                        <path
                            d='M414.549 23.86C421.132 23.86 426.469 18.5188 426.469 11.93C426.469 5.34124 421.132 0 414.549 0C407.966 0 402.629 5.34124 402.629 11.93C402.629 18.5188 407.966 23.86 414.549 23.86Z'
                            fill='#5F249F'
                        />
                        <path
                            d='M444.26 99.9904C450.843 99.9904 456.18 94.6536 456.18 88.0704C456.18 81.4872 450.843 76.1504 444.26 76.1504C437.677 76.1504 432.34 81.4872 432.34 88.0704C432.34 94.6536 437.677 99.9904 444.26 99.9904Z'
                            fill='#5F249F'
                        />
                        <path
                            d='M392 45.5299C398.583 45.5299 403.92 40.1887 403.92 33.5999C403.92 27.0112 398.583 21.6699 392 21.6699C385.417 21.6699 380.08 27.0112 380.08 33.5999C380.08 40.1887 385.417 45.5299 392 45.5299Z'
                            fill='#5F249F'
                        />
                        <path
                            d='M466.809 78.3405C473.392 78.3405 478.729 72.9992 478.729 66.4105C478.729 59.8217 473.392 54.4805 466.809 54.4805C460.225 54.4805 454.889 59.8217 454.889 66.4105C454.889 72.9992 460.225 78.3405 466.809 78.3405Z'
                            fill='#5F249F'
                        />
                        <path
                            d='M391.359 76.8004C397.943 76.8004 403.279 71.4592 403.279 64.8704C403.279 58.2817 397.943 52.9404 391.359 52.9404C384.776 52.9404 379.439 58.2817 379.439 64.8704C379.439 71.4592 384.776 76.8004 391.359 76.8004Z'
                            fill='#5F249F'
                        />
                        <path
                            d='M467.449 47.07C474.033 47.07 479.369 41.7287 479.369 35.14C479.369 28.5512 474.033 23.21 467.449 23.21C460.866 23.21 455.529 28.5512 455.529 35.14C455.529 41.7287 460.866 47.07 467.449 47.07Z'
                            fill='#5F249F'
                        />
                    </g>
                    <defs>
                        <clipPath id='clip0_172_14979'>
                            <rect width='702.76' height='100' fill='white' />
                        </clipPath>
                    </defs>
                </svg>
            </Box>
        </Box>
    );
};
