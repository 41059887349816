import {
    createContext,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { UserProfile } from 'src/types';
import { getUserProfileSelf, updateUserProfileSelf } from 'src/fetchers/user-profile.fetchers';
import { useOrganizationContext } from 'src/contexts/OrganizationContext';
import { parseFqn } from 'src/utils/user.utils';

type IUserProfileContext<T> = {
    userProfile: UserProfile | undefined;
    userProfileLoading: boolean;
    refreshUserProfile: () => void;
    updateUserProfile: (data: UserProfile) => Promise<UserProfile>;
    primaryUserProfile: UserProfile | undefined;
    refreshPrimaryUserProfile: () => void;
    updatePrimaryUserProfile: (data: UserProfile) => Promise<UserProfile>;
};

export const UserProfileContext = createContext<IUserProfileContext<any>>(
    {} as IUserProfileContext<any>,
);

const UserProfileContextProvider = ({ children }: PropsWithChildren) => {
    const [userProfile, setUserProfile] = useState<UserProfile>();
    const [userProfileLoading, setUserProfileLoading] = useState<boolean>(true);
    const { organization } = useOrganizationContext();

    const [primaryUserProfile, setPrimaryUserProfile] = useState<UserProfile>();

    const primaryOrganization = useCallback(() => {
        const { organization: primaryOrganization } = parseFqn(userProfile.username);
        return primaryOrganization;
    }, [userProfile]);

    const refreshUserProfile = useCallback(() => {
        if (!organization) {
            return;
        }
        setUserProfileLoading(true);
        getUserProfileSelf(organization.organizationName)
            .then((res) => {
                setUserProfile(res);
            })
            .finally(() => {
                setUserProfileLoading(false);
            });
    }, [setUserProfileLoading, setUserProfile, organization]);

    const refreshPrimaryUserProfile = useCallback(() => {
        getUserProfileSelf(primaryOrganization()).then((res) => {
            setPrimaryUserProfile(res);
        });
    }, [setPrimaryUserProfile, primaryOrganization]);

    const updateUserProfile = useCallback(
        async (data) => {
            return await updateUserProfileSelf(data, organization.organizationName);
        },
        [organization],
    );

    const updatePrimaryUserProfile = useCallback(
        async (data) => {
            function stripRestrictedPrimaryProfileUpdate(data: UserProfile) {
                delete data.address;
                delete data.givenName;
                delete data.familyName;
                delete data.fullName;
                return data;
            }

            return await updateUserProfileSelf(
                stripRestrictedPrimaryProfileUpdate(data),
                primaryOrganization(),
            );
        },
        [organization, primaryOrganization],
    );

    useEffect(() => {
        refreshUserProfile();
    }, [refreshUserProfile]);

    useEffect(() => {
        if (userProfile?.username) {
            refreshPrimaryUserProfile();
        }
    }, [refreshPrimaryUserProfile]);

    const contextValue = useMemo(
        () => ({
            userProfile,
            refreshUserProfile,
            updateUserProfile,
            userProfileLoading,
            primaryUserProfile,
            refreshPrimaryUserProfile,
            updatePrimaryUserProfile,
        }),
        [
            userProfile,
            refreshUserProfile,
            updateUserProfile,
            userProfileLoading,
            primaryUserProfile,
            refreshPrimaryUserProfile,
            updatePrimaryUserProfile,
        ],
    );

    return (
        <UserProfileContext.Provider value={contextValue}>{children}</UserProfileContext.Provider>
    );
};

export const useUserProfileContext = <T extends object | undefined>() => {
    return useContext(UserProfileContext) as IUserProfileContext<T>;
};

export default UserProfileContextProvider;
