import { Stack, SxProps, Theme, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { ThemeValues } from 'src/constants/theme-values';

export const SettingInputLabel = ({
    title,
    description,
    children,
    sx = {},
}: {
    title: string;
    description: string;
    children: ReactNode;
    sx?: SxProps;
}) => {
    return (
        <Stack
            sx={{
                width: ThemeValues.UserConfigSwitcherWidth,
                ...sx,
            }}
            rowGap='14px'
        >
            <Stack>
                <Typography fontWeight={500} variant='subtitle1'>
                    {title}
                </Typography>
                <Typography color={(theme: Theme) => theme.palette.text.secondary} variant='body1'>
                    {description}
                </Typography>
            </Stack>
            {children}
        </Stack>
    );
};
