import { Link, ListItem, ListItemButton, useTheme } from '@mui/material';
import { SwitcherDrawer } from 'src/components/common/Switcher/SwitcherDrawer';
import { AppConstants } from 'src/constants/app';
import { ThemeValues } from 'src/constants/theme-values';
import { useApplicationsContext, useOrganizationContext } from 'src/contexts';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { SharedApplicationModel } from 'src/types/application.type';
import { getContextRedirectUrl } from 'src/utils/navigation.utils';
import { ApplicationSwitcherOption } from './ApplicationSwitcherOption';

export const ApplicationSwitcherDrawer = ({
    applications,
    menuOpen,
    onMenuOpen,
    onMenuClose,
}: {
    applications: SharedApplicationModel[];
    menuOpen: boolean;
    onMenuOpen: () => void;
    onMenuClose: () => void;
}) => {
    const { t } = useTranslationNs();
    const { setIsAppDialogOpen, application } = useApplicationsContext();
    const { organization } = useOrganizationContext();

    const theme = useTheme();

    const handleAppClick = () => {
        onMenuClose();
    };

    const handleViewAll = () => {
        onMenuClose();
        setIsAppDialogOpen(true);
    };

    const allApps = applications.slice(0, AppConstants.AppMenuMax).map((app) => {
        return (
            <ListItem key={app.name} disablePadding>
                <ListItemButton
                    sx={{
                        ...theme.typography.subtitle1,
                        padding: ThemeValues.DrawerListItemPadding,
                    }}
                    component={Link}
                    href={getContextRedirectUrl(
                        organization?.organizationName,
                        app.baseUrl || app.redirectUrl,
                    )}
                    target='_blank'
                    selected={app.name === application?.name}
                    onClick={handleAppClick}
                >
                    <ApplicationSwitcherOption app={app} />
                </ListItemButton>
            </ListItem>
        );
    });

    return (
        <SwitcherDrawer
            menuOpen={menuOpen}
            menuTitle={t('APPLICATION_CONTEXT.MENU.TITLE')}
            menuCloseTitle={t('APPLICATION_CONTEXT.ACCESSIBILITY.CLOSE')}
            ctaTitle={t('APPLICATION_CONTEXT.MENU.MANAGE_ALL_APPS')}
            onMenuClose={onMenuClose}
            onMenuOpen={onMenuOpen}
            onCtaClick={handleViewAll}
        >
            {allApps}
        </SwitcherDrawer>
    );
};
