import { DisplaySettings } from '@mui/icons-material';
import { SettingsPanelBox } from 'src/components/user-settings/common/SettingsPanel';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { ThemePreferences } from './Theme/ThemePreferences';
import { DisplayNamePreferences } from 'src/components/user-settings/preferences/display/Theme/DisplayNamePreferences';
import { useRccContext } from 'src/contexts';

export const DisplayPreferences = () => {
    const { t } = useTranslationNs({
        keyPrefix: 'USER_SETTINGS.PREFERENCES.DISPLAY',
    });
    const { _TEMPORARY_globalProfileOktaSdkEnabled } = useRccContext();

    return (
        <SettingsPanelBox title={t('TITLE')} icon={<DisplaySettings />}>
            {!_TEMPORARY_globalProfileOktaSdkEnabled && <DisplayNamePreferences />}
            <ThemePreferences />
        </SettingsPanelBox>
    );
};
