import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ThemeValues } from 'src/constants/theme-values';

const useDesktopView = () => {
    const theme = useTheme();
    return useMediaQuery(theme.breakpoints.up(ThemeValues.Breakpoints.md));
};

export default useDesktopView;
