import { ListItem, ListItemButton, useTheme } from '@mui/material';
import { SwitcherDrawer } from 'src/components/common/Switcher/SwitcherDrawer';
import { AppConstants } from 'src/constants/app';
import { ThemeValues } from 'src/constants/theme-values';
import { useOrganizationContext } from 'src/contexts';
import { useOrganizationsCacheContextInternal } from 'src/contexts/OrganizationsCacheContext';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { Organization } from 'src/types';
import { RecentOrganization } from 'src/types/user-preferences.type';

export const OrganizationSwitcherDrawer = ({
    menuOpen,
    onMenuOpen,
    onMenuClose,
    onOrgClick,
}: {
    menuOpen: boolean;
    onMenuOpen: () => void;
    onMenuClose: () => void;
    onOrgClick: (orgName: string) => void;
}) => {
    const { t } = useTranslationNs();
    const { userOrganizations, setIsOrgDialogOpen, organization } = useOrganizationContext();
    const { recentOrganizations } = useOrganizationsCacheContextInternal();
    const recentsEnabled = recentOrganizations?.length > 0;
    const orgList: RecentOrganization[] | Organization[] = recentsEnabled
        ? recentOrganizations
        : userOrganizations;

    const theme = useTheme();

    const handleViewAll = () => {
        onMenuClose();
        setIsOrgDialogOpen(true);
    };

    const effectiveOrgs = orgList.slice(0, AppConstants.OrgMenuMax).map((org: Organization) => {
        const orgName = org.organizationName;

        return (
            <ListItem key={orgName} disablePadding>
                <ListItemButton
                    role='button'
                    sx={{
                        ...theme.typography.subtitle1,
                        padding: ThemeValues.DrawerListItemPadding,
                    }}
                    selected={orgName === organization?.organizationName}
                    onClick={() => onOrgClick(orgName)}
                >
                    {orgName}
                </ListItemButton>
            </ListItem>
        );
    });

    return (
        <SwitcherDrawer
            menuOpen={menuOpen}
            menuTitle={t('ORG_CONTEXT.MENU.TITLE')}
            menuCloseTitle={t('ORG_CONTEXT.ACCESSIBILITY.CLOSE')}
            ctaTitle={t('ORG_CONTEXT.MENU.MANAGE_ALL_ORGS')}
            onMenuClose={onMenuClose}
            onMenuOpen={onMenuOpen}
            onCtaClick={handleViewAll}
        >
            {effectiveOrgs}
        </SwitcherDrawer>
    );
};
