import Button, { ButtonProps } from '@mui/material/Button';
import { useOktaAuth } from '@okta/okta-react';
import { GlobalEmitter } from 'src/constants/global';
import { useClearNonRccCachesOnLogout } from 'src/hooks/useClearNonRccCachesOnLogout';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { RccEventKeys } from 'src/types';

export default function LogoutButton({
    onClick,
    ...props
}: Omit<ButtonProps, 'type' | 'onClick'> & { onClick?: () => void }) {
    const { oktaAuth } = useOktaAuth();
    const { t } = useTranslationNs();
    const clearCaches = useClearNonRccCachesOnLogout();

    const handleLogout = async () => {
        if (onClick) onClick();
        clearCaches();
        GlobalEmitter.emit(RccEventKeys.SIGNOUT);
        await oktaAuth.signOut();
    };

    return (
        <Button {...props} type='button' onClick={handleLogout} data-element-id='logout-button'>
            {t('COMMON.BUTTONS.LOGOUT')}
        </Button>
    );
}
