import OktaAuth, { UserClaims } from '@okta/okta-auth-js';
import { AppConstants } from 'src/constants/app';

const fullyQualifiedUsernameRegex = /acct:(?<org>[^@]*)@(?<username>.*)/;

export const isInternalUser = (user: UserClaims): boolean | undefined => {
    if (!user) return undefined;

    return user?.email?.endsWith(AppConstants.NcrEmailFormat);
};

// Account for case where email reflects previous while username reflects new unverified.
export const getEmail = (user: UserClaims): string => {
    return user.email_verified ? user.email : user.preferred_username;
};

export function parseFqn(fqn: string): { organization: string; username: string } {
    let match = fqn.match(fullyQualifiedUsernameRegex);

    return {
        organization: match?.groups?.org ?? '',
        username: match?.groups?.username ?? '',
    };
}
