import { MutableRefObject, ReactNode, useRef, useState } from 'react';
import RccBoundary from 'src/components/common/RccBoundary/RccBoundary';
import { Switcher } from 'src/components/common/Switcher/Switcher';
import {
    SwitcherProvider,
    getDefaultSwitcherConfig,
} from 'src/components/common/Switcher/SwitcherProvider';
import { useOrganizationContext, useRccContext } from 'src/contexts';
import { useApplicationsContextInternal } from 'src/contexts/ApplicationsContext';
import { SwitcherOptions } from 'src/contexts/SwitcherContext';
import useMobileView from 'src/hooks/useMobileView';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { ApplicationSwitcherDialog } from '../ApplicationSwitcherDialog/ApplicationSwitcherDialog';
import { ApplicationSwitcherDrawer } from './ApplicationSwitcherDrawer';
import { ApplicationSwitcherIcon } from './ApplicationSwitcherIcon';
import { ApplicationSwitcherMenu } from './ApplicationSwitcherMenu';

export const ApplicationSwitcher = ({
    config = getDefaultSwitcherConfig(),
}: {
    config?: SwitcherOptions;
}) => {
    const { t } = useTranslationNs();
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const { isAuthenticated } = useRccContext();
    const { isLoadingOrg, hasPreloaded } = useOrganizationContext();
    const {
        isLoading: isLoadingApp,
        application,
        getSwitcherList,
    } = useApplicationsContextInternal();
    const buttonRef: MutableRefObject<HTMLButtonElement | null> = useRef(null);
    const isMobile = useMobileView();
    const applicationsList = getSwitcherList();

    const handleOpenAppMenu = () => {
        setMenuOpen(true);
    };

    const handleCloseAppMenu = () => {
        setMenuOpen(false);
    };

    const appDisplayName = application?.displayName || application?.name;
    const isLoading = isLoadingApp || isLoadingOrg || (!hasPreloaded && isAuthenticated);

    let switcherLabel: ReactNode;
    if (isLoading) switcherLabel = t('COMMON.LOADING');
    else if (!application) switcherLabel = t('APPLICATION_CONTEXT.SWITCHER.EMPTY');
    else switcherLabel = appDisplayName;

    return (
        <RccBoundary>
            <SwitcherProvider config={config || {}}>
                <Switcher
                    tooltipTitle={switcherLabel.toString()}
                    disabled={isLoading || applicationsList.length < 1}
                    enableSwitcher={applicationsList.length > 0}
                    isLoading={isLoading}
                    onClick={handleOpenAppMenu}
                    ref={buttonRef}
                    data-element-id='app-switcher-nav-button'
                    startIcon={
                        <ApplicationSwitcherIcon
                            imageUrl={application?.imageUrl}
                            appName={appDisplayName}
                        />
                    }
                >
                    {switcherLabel}
                </Switcher>
                {isMobile ? (
                    <ApplicationSwitcherDrawer
                        applications={applicationsList}
                        menuOpen={menuOpen}
                        onMenuOpen={handleOpenAppMenu}
                        onMenuClose={handleCloseAppMenu}
                    />
                ) : (
                    <ApplicationSwitcherMenu
                        applications={applicationsList}
                        anchorRef={buttonRef}
                        menuOpen={menuOpen}
                        onMenuClose={handleCloseAppMenu}
                    />
                )}
                <ApplicationSwitcherDialog />
            </SwitcherProvider>
        </RccBoundary>
    );
};
