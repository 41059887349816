import { List, ListItemButton, ListItemIcon, ListItemText, Stack, Typography } from '@mui/material';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { NcrVoyixLogo } from 'src/components/common/NcrVoyixLogo/NcrVoyixLogo';
import {
    UserSettingsTabData,
    UserSettingsModalNavigationProps,
} from 'src/components/user-settings/user-settings-navigation';

const LogoWidth = 22;

const a11yTabPrefix = 'account-tab';
const a11yTabPanelPrefix = 'account-tabpanel';
const a11yProps = (index: number) => ({
    id: `${a11yTabPrefix}-${index}`,
    'aria-controls': `${a11yTabPanelPrefix}-${index}`,
});

const UserSettingsModalLogo = () => {
    const { t } = useTranslationNs({
        keyPrefix: 'USER_SETTINGS',
    });

    return (
        <Stack fontSize={`${LogoWidth}px`} direction='row' columnGap='8px' alignItems='flex-end'>
            <NcrVoyixLogo width='122px' />
            <Typography lineHeight={0.7} fontWeight={400} variant='h4' fontSize='20px'>
                {t('TITLE')}
            </Typography>
        </Stack>
    );
};

const UserSettingsModalSidenav = ({
    tabs,
    activeTabIndex,
    handleTabChange,
}: UserSettingsModalNavigationProps) => {
    return (
        <Stack
            sx={{
                backgroundColor: 'background.default',
                width: '240px',
                height: 'inherit',
                padding: '12px',
                borderRight: '1px solid #E8E8E8',
            }}
        >
            <div
                style={{
                    padding: '16px',
                }}
            >
                <UserSettingsModalLogo />
            </div>
            <List>
                {tabs.map((td: UserSettingsTabData, tabNumber: number) => {
                    const iconSize = '16px';
                    const isActive = activeTabIndex === tabNumber;
                    return (
                        <ListItemButton
                            selected={isActive}
                            key={td.title}
                            {...a11yProps(tabNumber)}
                            onClick={(e) => handleTabChange(e, tabNumber)}
                            sx={{
                                borderRadius: '8px',
                                my: '8px',
                                height: '36px',
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: `calc(${iconSize} + 8px)`,
                                    '.MuiSvgIcon-root': {
                                        fontSize: iconSize,
                                        width: iconSize,
                                        height: iconSize,
                                        color: isActive ? 'text.primary' : 'text.secondary',
                                    },
                                }}
                            >
                                {td.icon}
                            </ListItemIcon>
                            <ListItemText
                                primaryTypographyProps={{
                                    fontSize: '14px',
                                    fontWeight: '600',
                                    color: isActive ? 'text.primary' : 'text.secondary',
                                }}
                            >
                                {td.title}
                            </ListItemText>
                        </ListItemButton>
                    );
                })}
            </List>
        </Stack>
    );
};

export default UserSettingsModalSidenav;
