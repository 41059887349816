import { Box, Stack } from '@mui/material';
import { ReactNode } from 'react';

type TabPanelProps = {
    children?: ReactNode;
    index: number;
    value: number;
    tabPadding?: string;
    a11yTabPrefix?: string;
    a11yTabPanelPrefix?: string;
};

const wrapperStyles: { width: string; maxWidth: string } = {
    width: '100%',
    maxWidth: '820px',
};

const TabPanel = ({
    children,
    value,
    index,
    tabPadding,
    a11yTabPrefix,
    a11yTabPanelPrefix,
}: TabPanelProps) => {
    return (
        <Box
            role='tabpanel'
            hidden={value !== index}
            id={`${a11yTabPanelPrefix}-${index}`}
            aria-labelledby={`${a11yTabPrefix}-${index}`}
        >
            {value === index && (
                <Stack alignItems='center' padding={tabPadding}>
                    <Box {...wrapperStyles}>{children}</Box>
                </Stack>
            )}
        </Box>
    );
};

export default TabPanel;
