import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import useMobileView from 'src/hooks/useMobileView';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { StrengthBar } from 'src/components/common/StrengthBar/StrengthBar';

export class PasswordValidity {
    length: boolean;
    uppercase: boolean;
    lowercase: boolean;
    numbers: boolean;
    special: boolean;
    match: boolean;
    doesNotContainName: boolean;

    totalFields: number = 7;

    get validFields(): number {
        return (
            (this.length ? 1 : 0) +
            (this.uppercase ? 1 : 0) +
            (this.lowercase ? 1 : 0) +
            (this.numbers ? 1 : 0) +
            (this.special ? 1 : 0) +
            (this.doesNotContainName ? 1 : 0)
        );
    }

    public isValid() {
        return (
            this.length &&
            this.uppercase &&
            this.lowercase &&
            this.numbers &&
            this.special &&
            this.doesNotContainName
        );
    }

    public matches() {
        return this.match;
    }

    public static validatePassword(
        password: string,
        confirmedPassword: string,
        firstName: string,
        lastName: string,
    ): PasswordValidity {
        let validity = new PasswordValidity();
        validity.match = false;

        validity.length = password.length >= PasswordRequirements.minimumLength;
        validity.uppercase = PasswordRequirements.uppercaseRegEx.test(password);
        validity.lowercase = PasswordRequirements.lowerCaseRegEx.test(password);
        validity.numbers = PasswordRequirements.numberRegexp.test(password);
        validity.special = PasswordRequirements.specialCharRegexp.test(password);
        validity.match = password === confirmedPassword;

        validity.doesNotContainName = !(
            password.includes(firstName) || password.includes(lastName)
        );

        return validity;
    }
}

const PasswordRequirements = {
    minimumLength: 12,
    minimumUppercase: 1,
    minimumLowercase: 1,
    minimumNumbers: 1,
    minimumSpecial: 1,
    specialCharRegexp: /[!@#$%&*+~<>?\[\]{}]+/i,
    numberRegexp: /\d+/i,
    uppercaseRegEx: /[A-Z]+/,
    lowerCaseRegEx: /[a-z]+/,
};

function validatePassword(
    password: string,
    confirmedPassword: string,
    firstName: string,
    lastName: string,
): PasswordValidity {
    let validity = new PasswordValidity();
    validity.match = false;

    validity.length = password.length >= PasswordRequirements.minimumLength;
    validity.uppercase = PasswordRequirements.uppercaseRegEx.test(password);
    validity.lowercase = PasswordRequirements.lowerCaseRegEx.test(password);
    validity.numbers = PasswordRequirements.numberRegexp.test(password);
    validity.special = PasswordRequirements.specialCharRegexp.test(password);
    validity.match = password === confirmedPassword;
    validity.doesNotContainName = !(password.includes(firstName) && password.includes(lastName));

    return validity;
}

const PasswordValidationList = ({ passwordValid }: { passwordValid: PasswordValidity }) => {
    const { t } = useTranslationNs({ keyPrefix: 'USER_SETTINGS.SECURITY.PASSWORD_RESET' });
    const isMobileView = useMobileView();

    let passwordRequirementConfig = [
        {
            valid: passwordValid.length,
            resetText: t('REQUIREMENTS.MIN_CHARS', {
                number: PasswordRequirements.minimumLength,
            }),
        },
        {
            valid: passwordValid.uppercase,
            resetText: t('REQUIREMENTS.MIN_UPPER', {
                number: PasswordRequirements.minimumUppercase,
            }),
        },
        {
            valid: passwordValid.lowercase,
            resetText: t('REQUIREMENTS.MIN_LOWER', {
                number: PasswordRequirements.minimumLowercase,
            }),
        },
        {
            valid: passwordValid.numbers,
            resetText: t('REQUIREMENTS.MIN_NUMBER', {
                number: PasswordRequirements.minimumNumbers,
            }),
        },
        {
            valid: passwordValid.special,
            resetText: t('REQUIREMENTS.MIN_SPECIAL', {
                number: PasswordRequirements.minimumSpecial,
            }),
        },
        {
            valid: passwordValid.doesNotContainName,
            resetText: t('REQUIREMENTS.CONTAINS_NAME'),
        },
        {
            valid: passwordValid.match,
            resetText: t('REQUIREMENTS.MATCH'),
        },
    ];

    return (
        <Box
            pl={'10%'}
            sx={{
                marginTop: isMobileView ? 1 : 0,
                paddingLeft: isMobileView ? 0 : '10%',
            }}
        >
            <Typography
                variant='caption'
                align='left'
                sx={{
                    float: 'left',
                    fontSize: 14,
                    marginBottom: 3,
                    lineHeight: '14px',
                }}
            >
                {t('REQUIREMENTS.HEADER')}
            </Typography>
            <List
                sx={{
                    ml: 0,
                    paddingBottom: 0,
                }}
            >
                {passwordRequirementConfig.map((req) => (
                    <PasswordRequirement
                        key={req.resetText}
                        valid={req.valid}
                        description={req.resetText}
                    />
                ))}
            </List>
        </Box>
    );
};

const PasswordRequirement = (props: { valid: boolean; description: string }) => {
    return (
        <ListItem sx={{ padding: '0', paddingBottom: 0.5 }}>
            {props.valid ? <CheckCircleIcon color='success' /> : <CancelIcon color='error' />}
            <Typography variant='caption' sx={{ marginLeft: '8px', fontSize: 14 }}>
                {props.description}
            </Typography>
        </ListItem>
    );
};

export const PasswordStrengthBar = ({ passwordValid }: { passwordValid: PasswordValidity }) => {
    const { t } = useTranslationNs({ keyPrefix: 'USER_SETTINGS.SECURITY.PASSWORD_RESET' });

    let passwordRequirementConfig = [
        {
            valid: passwordValid.length,
            resetText: t('REQUIREMENTS.MIN_CHARS', {
                number: PasswordRequirements.minimumLength,
            }),
        },
        {
            valid: passwordValid.uppercase,
            resetText: t('REQUIREMENTS.MIN_UPPER', {
                number: PasswordRequirements.minimumUppercase,
            }),
        },
        {
            valid: passwordValid.lowercase,
            resetText: t('REQUIREMENTS.MIN_LOWER', {
                number: PasswordRequirements.minimumLowercase,
            }),
        },
        {
            valid: passwordValid.numbers,
            resetText: t('REQUIREMENTS.MIN_NUMBER', {
                number: PasswordRequirements.minimumNumbers,
            }),
        },
        {
            valid: passwordValid.special,
            resetText: t('REQUIREMENTS.MIN_SPECIAL', {
                number: PasswordRequirements.minimumSpecial,
            }),
        },
        {
            valid: passwordValid.doesNotContainName,
            resetText: t('REQUIREMENTS.CONTAINS_NAME'),
        },
    ];

    return (
        <StrengthBar
            value={passwordValid.validFields}
            max={passwordValid.totalFields - 1}
            message={
                passwordValid.isValid()
                    ? null
                    : 'Your password should contain at least ' +
                      passwordRequirementConfig
                          .filter((r) => !r.valid)
                          .map((r) => r.resetText)
                          .join(', ')
            }
        />
    );
};

export const PasswordMatchesMessage = ({ passwordValid }: { passwordValid: PasswordValidity }) => {
    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    gap: '8px',
                    alignItems: 'center',
                }}
            >
                <Typography>Password matches</Typography>
                {passwordValid.match ? (
                    <CheckCircleIcon color='success' />
                ) : (
                    <CancelIcon color='error' />
                )}
            </div>
        </div>
    );
};

export default PasswordValidationList;
