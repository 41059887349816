import { ReactNode, useMemo } from 'react';
import {
    IUserPreferencesContext,
    UserPreferencesContext,
} from 'src/contexts/UserPreferencesContext';
import { useGetUserPreferences } from 'src/fetchers/user-preferences.fetchers';

export const UserPreferencesProvider = ({ children }: { children: ReactNode }) => {
    const {
        userPreferences,
        isUserPreferencesLoading,
        isUserPreferencesValidating,
        getUserPreferencesError,
        isUserPreferencesMutating,
        userPreferencesMutationError,
        updateUserPreferences,
    } = useGetUserPreferences();

    const value: IUserPreferencesContext = useMemo(
        () => ({
            userPreferences,
            isUserPreferencesLoading,
            isUserPreferencesMutating,
            isUserPreferencesValidating,
            userPreferencesMutationError,
            getUserPreferencesError,
            updateUserPreferences,
        }),
        [
            userPreferences,
            isUserPreferencesLoading,
            isUserPreferencesMutating,
            isUserPreferencesValidating,
            userPreferencesMutationError,
            getUserPreferencesError,
            updateUserPreferences,
        ],
    );

    return (
        <UserPreferencesContext.Provider value={value}>{children}</UserPreferencesContext.Provider>
    );
};
