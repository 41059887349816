import { SettingsTabContainer } from 'src/components/user-settings/common/SettingContainer';
import { DefaultPreferences } from './defaults/DefaultPreferences';
import { DisplayPreferences } from './display/DisplayPreferences';

export const UserPreferences = () => {
    return (
        <SettingsTabContainer>
            <DefaultPreferences />
            <DisplayPreferences />
        </SettingsTabContainer>
    );
};
