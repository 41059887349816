import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { Collapse, Stack, Tab, Tabs, Theme, Typography } from '@mui/material';
import { ThemeValues } from 'src/constants/theme-values';
import {
    UserSettingsTabData,
    UserSettingsModalNavigationProps,
} from 'src/components/user-settings/user-settings-navigation';
import { NcrVoyixLogo } from 'src/components/common/NcrVoyixLogo/NcrVoyixLogo';

const LogoWidth = 22;

const a11yTabPrefix = 'account-tab';
const a11yTabPanelPrefix = 'account-tabpanel';
const a11yProps = (index: number) => ({
    id: `${a11yTabPrefix}-${index}`,
    'aria-controls': `${a11yTabPanelPrefix}-${index}`,
});

const wrapperStyles: { width: string; maxWidth: string } = {
    width: '100%',
    maxWidth: '820px',
};

const UserSettingsModalLogo = () => {
    const { t } = useTranslationNs({
        keyPrefix: 'USER_SETTINGS',
    });

    return (
        <Stack fontSize={`${LogoWidth}px`} direction='row' columnGap='8px' alignItems='flex-end'>
            <NcrVoyixLogo width='122px' />
            <Typography lineHeight={0.7} fontWeight={400} variant='h4' fontSize='20px'>
                {t('TITLE')}
            </Typography>
        </Stack>
    );
};

const UserSettingsModalHeader = ({
    tabs,
    activeTabIndex,
    handleTabChange,
    collapsed = false,
}: UserSettingsModalNavigationProps & { collapsed?: boolean }) => {
    const { t } = useTranslationNs({
        keyPrefix: 'USER_SETTINGS',
    });

    return (
        <Stack
            sx={{
                padding: `8px ${ThemeValues.UserSettingsPadding} 0px`,
                borderBottom: '1px solid',
                borderColor: 'divider',
                backgroundColor: 'background.default',
            }}
        >
            <Collapse in={!collapsed}>
                <div
                    style={{
                        padding: '16px',
                    }}
                >
                    <UserSettingsModalLogo />
                </div>
            </Collapse>

            <Stack alignItems='flex-start' rowGap='24px' {...wrapperStyles}>
                <Tabs
                    sx={(theme: Theme) => ({
                        '.MuiTabs-indicator': {
                            backgroundColor: theme.palette.text.primary,
                        },
                    })}
                    value={activeTabIndex}
                    onChange={handleTabChange}
                >
                    {tabs.map((td: UserSettingsTabData, idx: number) => (
                        <Tab
                            sx={(theme: Theme) => ({
                                textTransform: 'none',
                                '&.Mui-selected': {
                                    color: theme.palette.text.primary,
                                },
                            })}
                            key={td.title}
                            label={td.title}
                            {...a11yProps(idx)}
                        />
                    ))}
                </Tabs>
            </Stack>
        </Stack>
    );
};

export default UserSettingsModalHeader;
