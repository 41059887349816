import { Box, Divider, Menu, MenuItem, MenuList, Theme, Typography, useTheme } from '@mui/material';
import { MutableRefObject, useEffect, useState } from 'react';
import { UserProfileOption } from 'src/types/user-profile.type';
import LogoutButton from '../common/LogoutButton/LogoutButton';
import { ProfileIcon } from '../common/ProfileIcon/ProfileIcon';
import { useRccContext } from 'src/contexts';
import { ThemeValues } from 'src/constants/theme-values';
import { useLocation } from 'src/utils/search-params.utils';
import { Link } from 'src/components/common/Link/Link';
import { useUserProfileContext } from 'src/contexts/UserProfileContext';

const MenuIconOption = ({
    option,
    onClick,
    selected,
}: {
    option: UserProfileOption;
    onClick: () => void;
    selected: boolean;
}) => {
    const { linkProps = {}, onClick: onClickOption, icon, label, url } = option;

    const menuButton = (
        <Box display='flex' alignItems='center' padding='12px 16px'>
            {icon && (
                <Box
                    display='flex'
                    lineHeight={1}
                    paddingRight='8px'
                    color={(theme: Theme) => theme.palette.action.active}
                >
                    {icon}
                </Box>
            )}
            <Typography variant='body1'>{label}</Typography>
        </Box>
    );

    return (
        <MenuItem
            onClick={() => {
                if (onClickOption) onClickOption();
                onClick();
            }}
            sx={{
                padding: 0,
                a: {
                    textDecoration: 'none',
                    width: '100%',
                    color: 'inherit',
                },
            }}
            selected={selected}
        >
            {url !== undefined ? (
                <Link {...linkProps} to={url}>
                    {menuButton}
                </Link>
            ) : (
                menuButton
            )}
        </MenuItem>
    );
};

export const UserProfileSettingsMenu = ({
    anchorRef,
    menuOpen,
    onMenuClose,
    options,
}: {
    anchorRef: MutableRefObject<HTMLButtonElement | null>;
    menuOpen: boolean;
    onMenuClose: () => void;
    options: UserProfileOption[];
}) => {
    const { primaryUserProfile } = useUserProfileContext();
    const [hasUserName, setHasUserName] = useState<boolean>(false);
    const theme = useTheme();
    const location = useLocation();

    useEffect(() => {
        setHasUserName(
            Boolean(
                primaryUserProfile && primaryUserProfile.givenName && primaryUserProfile.familyName,
            ),
        );
    }, [primaryUserProfile]);

    return (
        <Menu
            anchorEl={() => anchorRef.current as HTMLButtonElement}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            sx={{
                '.MuiMenu-paper': {
                    minWidth: '288px',
                    borderRadius: ThemeValues.BorderRadius,
                },
            }}
            MenuListProps={{
                sx: {
                    padding: '4px 0px',
                },
            }}
            open={menuOpen}
            onClose={onMenuClose}
        >
            <Box display={hasUserName ? 'flex' : 'none'} alignItems='center' padding={'8px 12px'}>
                <Box paddingRight='16px'>
                    <ProfileIcon profileInfo={primaryUserProfile} size='laptop' />
                </Box>
                <Typography variant='body1'>
                    {primaryUserProfile?.displayName ?? primaryUserProfile?.fullName}
                </Typography>
            </Box>
            <Divider sx={{ display: hasUserName ? 'block' : 'none' }} />
            <MenuList sx={{ padding: '4px 0px', width: '100%' }} data-element-id='user-menu-list'>
                {options.map((option: UserProfileOption) => (
                    <MenuIconOption
                        key={option.label}
                        selected={location.pathname === option?.url}
                        option={option}
                        onClick={onMenuClose}
                    />
                ))}
            </MenuList>
            <Divider />
            <Box padding='4px' data-element-id='user-menu-footer'>
                <LogoutButton
                    onClick={onMenuClose}
                    sx={{
                        width: '100%',
                        justifyContent: 'flex-start',
                        textTransform: 'none',
                        padding: '8px',
                        color: theme.palette.text.primary,
                        fontWeight: theme.typography.body1.fontWeight,
                        fontSize: theme.typography.body1.fontSize,
                    }}
                />
            </Box>
        </Menu>
    );
};
