import { useRccContext } from 'src/contexts';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { Stack } from '@mui/material';
import { TextInput } from 'src/components/common/TextInput/TextInput';
import { useRef, useState } from 'react';
import PersonIcon from '@mui/icons-material/Person';
import { SettingsPanelBox, SettingsPanel } from 'src/components/user-settings/common/SettingsPanel';
import { oktaService } from 'src/fetchers/okta.fetchers';
import { useOktaAuth } from '@okta/okta-react';
import { ErrorInterface, formError, validateRequired } from 'src/utils/form.utils';
import { useSnackbarContext } from 'src/components/common/SnackBar/SnackBar';
import { getEmail } from 'src/utils/user.utils';
import Skeleton from '@mui/material/Skeleton';

interface UserProfileErrors extends ErrorInterface {
    firstName: null | formError.required;
    lastName: null | formError.required;
}

const PersonalInfo = () => {
    const { userInfo, refreshUserInfo, isInternalUser } = useRccContext();
    const { t } = useTranslationNs({
        keyPrefix: 'USER_SETTINGS.PROFILE',
    });
    const { t: commonNsTranslate } = useTranslationNs({ keyPrefix: 'COMMON' });
    const [editMode, setEditMode] = useState<boolean>(false);
    const firstNameRef = useRef<HTMLInputElement>(null);
    const lastNameRef = useRef<HTMLInputElement>(null);

    const [errors, setErrors] = useState<UserProfileErrors>({
        firstName: null,
        lastName: null,
    });

    const { oktaAuth } = useOktaAuth();
    const { setSnackOptions } = useSnackbarContext();

    const handleResponse = (message: string, status: 'success' | 'error') => {
        setSnackOptions({
            open: true,
            message: message,
            color: status,
        });
    };

    function saveForm() {
        oktaService
            .updateOktaProfile(
                oktaAuth,
                firstNameRef.current?.value,
                lastNameRef.current?.value,
                getEmail(userInfo),
            )
            .then(() => {
                handleResponse(commonNsTranslate('ALERTS.UPDATE_SUCCESS'), 'success');
            })
            .catch(() => {
                handleResponse(commonNsTranslate('ALERTS.UPDATE_FAILED'), 'error');
            })
            .finally(() => {
                refreshUserInfo();
                setEditMode(false);
            });
    }

    function cancelForm() {
        setEditMode(false);
    }

    if (!userInfo) {
        return <Skeleton variant='rounded' height={160} />;
    }

    if (isInternalUser) {
        return (
            <SettingsPanelBox title={t('PERSONAL_INFO')} icon={<PersonIcon />}>
                <Stack gap={'8px'}>
                    <TextInput
                        label={t('FIRST_NAME')}
                        defaultValue={userInfo.given_name}
                        disabled
                    />
                    <TextInput
                        label={t('LAST_NAME')}
                        defaultValue={userInfo.family_name}
                        disabled
                    />
                </Stack>
            </SettingsPanelBox>
        );
    }

    return (
        <SettingsPanel
            title={t('PERSONAL_INFO')}
            icon={<PersonIcon />}
            editMode={editMode}
            setEditMode={setEditMode}
            disabled={Boolean(errors.firstName) || Boolean(errors.lastName)}
            onSave={saveForm}
            onCancel={cancelForm}
        >
            <Stack gap={'8px'}>
                <TextInput
                    ref={firstNameRef}
                    required
                    label={t('FIRST_NAME')}
                    error={errors.firstName === formError.required}
                    onChange={(e) => {
                        validateRequired(e.target.value, 'firstName', errors, setErrors);
                    }}
                    editMode={editMode}
                    defaultValue={userInfo.given_name}
                />
                <TextInput
                    ref={lastNameRef}
                    required
                    label={t('LAST_NAME')}
                    error={errors.lastName === formError.required}
                    onChange={(e) => {
                        validateRequired(e.target.value, 'lastName', errors, setErrors);
                    }}
                    editMode={editMode}
                    defaultValue={userInfo.family_name}
                />
            </Stack>
        </SettingsPanel>
    );
};

export default PersonalInfo;
