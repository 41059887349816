import { HTMLAttributes } from 'react';
import { useTranslationNs } from 'src/hooks/useTranslationNs';

export const AppPlaceholder = (svgProps: HTMLAttributes<SVGElement>) => {
    const { t } = useTranslationNs();
    return (
        <svg
            {...svgProps}
            width='22'
            height='22'
            role='img'
            viewBox='0 0 22 22'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
        >
            <title>{t('APPLICATION_CONTEXT.SWITCHER.DEFAULT_ICON_ALT')}</title>
            <g id='Design'>
                <g id='Group'>
                    <path
                        id='Vector'
                        d='M10.9999 12.3285C11.7336 12.3285 12.3284 11.7337 12.3284 11.0001C12.3284 10.2664 11.7336 9.67163 10.9999 9.67163C10.2663 9.67163 9.67151 10.2664 9.67151 11.0001C9.67151 11.7337 10.2663 12.3285 10.9999 12.3285Z'
                        fill='#5F249F'
                    />
                    <path
                        id='Vector_2'
                        d='M14.2408 12.3285C14.9745 12.3285 15.5692 11.7337 15.5692 11.0001C15.5692 10.2664 14.9745 9.67163 14.2408 9.67163C13.5071 9.67163 12.9124 10.2664 12.9124 11.0001C12.9124 11.7337 13.5071 12.3285 14.2408 12.3285Z'
                        fill='#5F249F'
                    />
                    <path
                        id='Vector_3'
                        d='M17.4803 12.3285C18.214 12.3285 18.8087 11.7337 18.8087 11.0001C18.8087 10.2664 18.214 9.67163 17.4803 9.67163C16.7466 9.67163 16.1519 10.2664 16.1519 11.0001C16.1519 11.7337 16.7466 12.3285 17.4803 12.3285Z'
                        fill='#5F249F'
                    />
                    <path
                        id='Vector_4'
                        d='M20.3963 12.0037C20.9506 12.0037 21.3999 11.5543 21.3999 11C21.3999 10.4457 20.9506 9.99634 20.3963 9.99634C19.8419 9.99634 19.3926 10.4457 19.3926 11C19.3926 11.5543 19.8419 12.0037 20.3963 12.0037Z'
                        fill='#5F249F'
                    />
                    <path
                        id='Vector_5'
                        d='M20.3963 8.50394C20.8068 8.50394 21.1397 8.17109 21.1397 7.76051C21.1397 7.34993 20.8068 7.01709 20.3963 7.01709C19.9857 7.01709 19.6528 7.34993 19.6528 7.76051C19.6528 8.17109 19.9857 8.50394 20.3963 8.50394Z'
                        fill='#5F249F'
                    />
                    <path
                        id='Vector_6'
                        d='M20.3963 14.9842C20.8068 14.9842 21.1397 14.6513 21.1397 14.2407C21.1397 13.8302 20.8068 13.4973 20.3963 13.4973C19.9857 13.4973 19.6528 13.8302 19.6528 14.2407C19.6528 14.6513 19.9857 14.9842 20.3963 14.9842Z'
                        fill='#5F249F'
                    />
                    <path
                        id='Vector_7'
                        d='M11 2.60745C11.5543 2.60745 12.0037 2.15809 12.0037 1.60378C12.0037 1.04946 11.5543 0.600098 11 0.600098C10.4457 0.600098 9.99634 1.04946 9.99634 1.60378C9.99634 2.15809 10.4457 2.60745 11 2.60745Z'
                        fill='#5F249F'
                    />
                    <path
                        id='Vector_8'
                        d='M4.51967 5.52347C5.07398 5.52347 5.52335 5.07411 5.52335 4.51979C5.52335 3.96547 5.07398 3.51611 4.51967 3.51611C3.96535 3.51611 3.51599 3.96547 3.51599 4.51979C3.51599 5.07411 3.96535 5.52347 4.51967 5.52347Z'
                        fill='#5F249F'
                    />
                    <path
                        id='Vector_9'
                        d='M4.51967 18.4842C5.07398 18.4842 5.52335 18.0348 5.52335 17.4805C5.52335 16.9262 5.07398 16.4768 4.51967 16.4768C3.96535 16.4768 3.51599 16.9262 3.51599 17.4805C3.51599 18.0348 3.96535 18.4842 4.51967 18.4842Z'
                        fill='#5F249F'
                    />
                    <path
                        id='Vector_10'
                        d='M17.4804 5.52347C18.0347 5.52347 18.484 5.07411 18.484 4.51979C18.484 3.96547 18.0347 3.51611 17.4804 3.51611C16.926 3.51611 16.4767 3.96547 16.4767 4.51979C16.4767 5.07411 16.926 5.52347 17.4804 5.52347Z'
                        fill='#5F249F'
                    />
                    <path
                        id='Vector_11'
                        d='M17.4804 18.4842C18.0347 18.4842 18.484 18.0348 18.484 17.4805C18.484 16.9262 18.0347 16.4768 17.4804 16.4768C16.926 16.4768 16.4767 16.9262 16.4767 17.4805C16.4767 18.0348 16.926 18.4842 17.4804 18.4842Z'
                        fill='#5F249F'
                    />
                    <path
                        id='Vector_12'
                        d='M7.76039 2.3472C8.17097 2.3472 8.50381 2.01436 8.50381 1.60377C8.50381 1.19319 8.17097 0.860352 7.76039 0.860352C7.34981 0.860352 7.01697 1.19319 7.01697 1.60377C7.01697 2.01436 7.34981 2.3472 7.76039 2.3472Z'
                        fill='#5F249F'
                    />
                    <path
                        id='Vector_13'
                        d='M14.2407 2.3472C14.6513 2.3472 14.9842 2.01436 14.9842 1.60377C14.9842 1.19319 14.6513 0.860352 14.2407 0.860352C13.8302 0.860352 13.4973 1.19319 13.4973 1.60377C13.4973 2.01436 13.8302 2.3472 14.2407 2.3472Z'
                        fill='#5F249F'
                    />
                    <path
                        id='Vector_14'
                        d='M1.60365 12.0037C2.15797 12.0037 2.60733 11.5543 2.60733 11C2.60733 10.4457 2.15797 9.99634 1.60365 9.99634C1.04934 9.99634 0.599976 10.4457 0.599976 11C0.599976 11.5543 1.04934 12.0037 1.60365 12.0037Z'
                        fill='#5F249F'
                    />
                    <path
                        id='Vector_15'
                        d='M1.60365 14.9842C2.01423 14.9842 2.34708 14.6513 2.34708 14.2407C2.34708 13.8302 2.01423 13.4973 1.60365 13.4973C1.19307 13.4973 0.860229 13.8302 0.860229 14.2407C0.860229 14.6513 1.19307 14.9842 1.60365 14.9842Z'
                        fill='#5F249F'
                    />
                    <path
                        id='Vector_16'
                        d='M1.60365 8.50394C2.01423 8.50394 2.34708 8.17109 2.34708 7.76051C2.34708 7.34993 2.01423 7.01709 1.60365 7.01709C1.19307 7.01709 0.860229 7.34993 0.860229 7.76051C0.860229 8.17109 1.19307 8.50394 1.60365 8.50394Z'
                        fill='#5F249F'
                    />
                    <path
                        id='Vector_17'
                        d='M11 21.4002C11.5543 21.4002 12.0037 20.9508 12.0037 20.3965C12.0037 19.8422 11.5543 19.3928 11 19.3928C10.4457 19.3928 9.99634 19.8422 9.99634 20.3965C9.99634 20.9508 10.4457 21.4002 11 21.4002Z'
                        fill='#5F249F'
                    />
                    <path
                        id='Vector_18'
                        d='M14.2407 21.1399C14.6513 21.1399 14.9842 20.8071 14.9842 20.3965C14.9842 19.9859 14.6513 19.6531 14.2407 19.6531C13.8302 19.6531 13.4973 19.9859 13.4973 20.3965C13.4973 20.8071 13.8302 21.1399 14.2407 21.1399Z'
                        fill='#5F249F'
                    />
                    <path
                        id='Vector_19'
                        d='M7.76039 21.1399C8.17097 21.1399 8.50381 20.8071 8.50381 20.3965C8.50381 19.9859 8.17097 19.6531 7.76039 19.6531C7.34981 19.6531 7.01697 19.9859 7.01697 20.3965C7.01697 20.8071 7.34981 21.1399 7.76039 21.1399Z'
                        fill='#5F249F'
                    />
                    <path
                        id='Vector_20'
                        d='M7.76044 12.3285C8.49411 12.3285 9.08887 11.7337 9.08887 11.0001C9.08887 10.2664 8.49411 9.67163 7.76044 9.67163C7.02676 9.67163 6.43201 10.2664 6.43201 11.0001C6.43201 11.7337 7.02676 12.3285 7.76044 12.3285Z'
                        fill='#5F249F'
                    />
                    <path
                        id='Vector_21'
                        d='M4.51959 12.3285C5.25326 12.3285 5.84802 11.7337 5.84802 11.0001C5.84802 10.2664 5.25326 9.67163 4.51959 9.67163C3.78592 9.67163 3.19116 10.2664 3.19116 11.0001C3.19116 11.7337 3.78592 12.3285 4.51959 12.3285Z'
                        fill='#5F249F'
                    />
                    <path
                        id='Vector_22'
                        d='M10.9999 9.08899C11.7336 9.08899 12.3284 8.49423 12.3284 7.76056C12.3284 7.02689 11.7336 6.43213 10.9999 6.43213C10.2663 6.43213 9.67151 7.02689 9.67151 7.76056C9.67151 8.49423 10.2663 9.08899 10.9999 9.08899Z'
                        fill='#5F249F'
                    />
                    <path
                        id='Vector_23'
                        d='M10.9999 15.5692C11.7336 15.5692 12.3284 14.9745 12.3284 14.2408C12.3284 13.5071 11.7336 12.9124 10.9999 12.9124C10.2663 12.9124 9.67151 13.5071 9.67151 14.2408C9.67151 14.9745 10.2663 15.5692 10.9999 15.5692Z'
                        fill='#5F249F'
                    />
                    <path
                        id='Vector_24'
                        d='M14.2408 9.08899C14.9745 9.08899 15.5692 8.49423 15.5692 7.76056C15.5692 7.02689 14.9745 6.43213 14.2408 6.43213C13.5071 6.43213 12.9124 7.02689 12.9124 7.76056C12.9124 8.49423 13.5071 9.08899 14.2408 9.08899Z'
                        fill='#5F249F'
                    />
                    <path
                        id='Vector_25'
                        d='M14.2408 15.5692C14.9745 15.5692 15.5692 14.9745 15.5692 14.2408C15.5692 13.5071 14.9745 12.9124 14.2408 12.9124C13.5071 12.9124 12.9124 13.5071 12.9124 14.2408C12.9124 14.9745 13.5071 15.5692 14.2408 15.5692Z'
                        fill='#5F249F'
                    />
                    <path
                        id='Vector_26'
                        d='M17.4803 9.08899C18.214 9.08899 18.8087 8.49423 18.8087 7.76056C18.8087 7.02689 18.214 6.43213 17.4803 6.43213C16.7466 6.43213 16.1519 7.02689 16.1519 7.76056C16.1519 8.49423 16.7466 9.08899 17.4803 9.08899Z'
                        fill='#5F249F'
                    />
                    <path
                        id='Vector_27'
                        d='M17.4803 15.5692C18.214 15.5692 18.8087 14.9745 18.8087 14.2408C18.8087 13.5071 18.214 12.9124 17.4803 12.9124C16.7466 12.9124 16.1519 13.5071 16.1519 14.2408C16.1519 14.9745 16.7466 15.5692 17.4803 15.5692Z'
                        fill='#5F249F'
                    />
                    <path
                        id='Vector_28'
                        d='M7.76044 9.08899C8.49411 9.08899 9.08887 8.49423 9.08887 7.76056C9.08887 7.02689 8.49411 6.43213 7.76044 6.43213C7.02676 6.43213 6.43201 7.02689 6.43201 7.76056C6.43201 8.49423 7.02676 9.08899 7.76044 9.08899Z'
                        fill='#5F249F'
                    />
                    <path
                        id='Vector_29'
                        d='M7.76044 15.5692C8.49411 15.5692 9.08887 14.9745 9.08887 14.2408C9.08887 13.5071 8.49411 12.9124 7.76044 12.9124C7.02676 12.9124 6.43201 13.5071 6.43201 14.2408C6.43201 14.9745 7.02676 15.5692 7.76044 15.5692Z'
                        fill='#5F249F'
                    />
                    <path
                        id='Vector_30'
                        d='M4.51959 9.08899C5.25326 9.08899 5.84802 8.49423 5.84802 7.76056C5.84802 7.02689 5.25326 6.43213 4.51959 6.43213C3.78592 6.43213 3.19116 7.02689 3.19116 7.76056C3.19116 8.49423 3.78592 9.08899 4.51959 9.08899Z'
                        fill='#5F249F'
                    />
                    <path
                        id='Vector_31'
                        d='M4.51959 15.5692C5.25326 15.5692 5.84802 14.9745 5.84802 14.2408C5.84802 13.5071 5.25326 12.9124 4.51959 12.9124C3.78592 12.9124 3.19116 13.5071 3.19116 14.2408C3.19116 14.9745 3.78592 15.5692 4.51959 15.5692Z'
                        fill='#5F249F'
                    />
                    <path
                        id='Vector_32'
                        d='M10.9999 5.84827C11.7336 5.84827 12.3284 5.25351 12.3284 4.51984C12.3284 3.78616 11.7336 3.19141 10.9999 3.19141C10.2663 3.19141 9.67151 3.78616 9.67151 4.51984C9.67151 5.25351 10.2663 5.84827 10.9999 5.84827Z'
                        fill='#5F249F'
                    />
                    <path
                        id='Vector_33'
                        d='M10.9999 18.809C11.7336 18.809 12.3284 18.2142 12.3284 17.4805C12.3284 16.7469 11.7336 16.1521 10.9999 16.1521C10.2663 16.1521 9.67151 16.7469 9.67151 17.4805C9.67151 18.2142 10.2663 18.809 10.9999 18.809Z'
                        fill='#5F249F'
                    />
                    <path
                        id='Vector_34'
                        d='M7.76044 5.84827C8.49411 5.84827 9.08887 5.25351 9.08887 4.51984C9.08887 3.78616 8.49411 3.19141 7.76044 3.19141C7.02676 3.19141 6.43201 3.78616 6.43201 4.51984C6.43201 5.25351 7.02676 5.84827 7.76044 5.84827Z'
                        fill='#5F249F'
                    />
                    <path
                        id='Vector_35'
                        d='M14.2408 5.84827C14.9745 5.84827 15.5692 5.25351 15.5692 4.51984C15.5692 3.78616 14.9745 3.19141 14.2408 3.19141C13.5071 3.19141 12.9124 3.78616 12.9124 4.51984C12.9124 5.25351 13.5071 5.84827 14.2408 5.84827Z'
                        fill='#5F249F'
                    />
                    <path
                        id='Vector_36'
                        d='M7.76044 18.809C8.49411 18.809 9.08887 18.2142 9.08887 17.4805C9.08887 16.7469 8.49411 16.1521 7.76044 16.1521C7.02676 16.1521 6.43201 16.7469 6.43201 17.4805C6.43201 18.2142 7.02676 18.809 7.76044 18.809Z'
                        fill='#5F249F'
                    />
                    <path
                        id='Vector_37'
                        d='M14.2408 18.809C14.9745 18.809 15.5692 18.2142 15.5692 17.4805C15.5692 16.7469 14.9745 16.1521 14.2408 16.1521C13.5071 16.1521 12.9124 16.7469 12.9124 17.4805C12.9124 18.2142 13.5071 18.809 14.2408 18.809Z'
                        fill='#5F249F'
                    />
                </g>
            </g>
        </svg>
    );
};
