import { TextField, TextFieldProps, Typography } from '@mui/material';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface TextFieldControlledInputProps extends Omit<TextFieldProps, 'name' | 'control' | 'error'> {
    name: string;
    control: Control<any>;
    errors: FieldErrors<any>;
}

const TextFieldControlledInput = ({
    name,
    control,
    label,
    errors,
    ...props
}: TextFieldControlledInputProps) => {
    const { t } = useTranslation();

    let fieldErrors = errors[name];

    return (
        <Controller
            render={({ field }) => (
                <TextField
                    {...field}
                    {...props}
                    error={Boolean(fieldErrors)}
                    helperText={
                        fieldErrors?.message && typeof fieldErrors?.message === 'string'
                            ? t(fieldErrors?.message)
                            : ''
                    }
                    label={label}
                    onBlur={() => {
                        if (field.value) {
                            field.onChange(field.value.trim());
                        }
                    }}
                />
            )}
            name={name}
            control={control}
        />
    );
};

export const ReadOnlyField = ({
    label,
    value,
}: {
    label?: string | null;
    value?: string | null;
}) => (
    <>
        {label && (
            <Typography
                sx={{
                    fontWeight: 700,
                }}
            >
                {label}
            </Typography>
        )}
        <Typography
            sx={{
                minHeight: '1.5em',
                wordWrap: 'break-word',
            }}
        >
            {value && value !== '' ? value : '-'}
        </Typography>
    </>
);

export default TextFieldControlledInput;
