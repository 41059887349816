import { useEffect, useState } from 'react';
import { SettingInputLabel } from 'src/components/user-settings/common/SettingInputLabel';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import SettingPopupInput from 'src/components/user-settings/common/SettingPopupInput';
import { UserProfile } from 'src/types';
import { useUserProfileContext } from 'src/contexts/UserProfileContext';
import { AxiosError } from 'axios';
import { useSnackbarContext } from 'src/components/common/SnackBar/SnackBar';

export const DisplayNamePreferences = () => {
    const { t } = useTranslationNs({
        keyPrefix: 'USER_SETTINGS.PREFERENCES.DISPLAY.DISPLAY_NAME',
    });

    const { setSnackOptions } = useSnackbarContext();

    const { primaryUserProfile, refreshPrimaryUserProfile, updatePrimaryUserProfile } =
        useUserProfileContext();

    const [inputValue, setInputValue] = useState<string>(
        primaryUserProfile?.displayName ?? primaryUserProfile?.fullName ?? '',
    );

    useEffect(() => {
        setInputValue(primaryUserProfile?.displayName ?? primaryUserProfile?.fullName ?? '');
    }, [primaryUserProfile]);

    const handleSaveClick = async () => {
        const updatedProfile: UserProfile = { ...primaryUserProfile, displayName: inputValue };
        updatePrimaryUserProfile(updatedProfile)
            .then(() => {
                refreshPrimaryUserProfile();
            })
            .catch((error: AxiosError) => {
                setSnackOptions({
                    open: true,
                    color: 'error',
                    message: error.message,
                });
            });
    };

    return (
        <SettingInputLabel title={t('TITLE')} description={t('DESCRIPTION')}>
            <SettingPopupInput
                label={t('TITLE')}
                prompt={t('PROMPT')}
                value={inputValue}
                onChange={(newValue) => setInputValue(newValue)}
                onSave={() => handleSaveClick()}
            />
        </SettingInputLabel>
    );
};
