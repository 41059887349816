import { QueryParamKeys } from '../types';

export const getContextRedirectUrl = (
    organizationName: string,
    url: string,
): string => {
    if (!url) return "";

    let redirect = new URL(url);

    if (organizationName) {
        redirect.searchParams.set(QueryParamKeys.organization, organizationName);
    }

    return redirect.href;
};
