import DomainIcon from '@mui/icons-material/Domain';
import { List, ListItemIcon, Typography } from '@mui/material';
import { DialogListItemActionButton } from 'src/components/common/Dialog/DialogListItemActionButton';
import { useOrganizationContext } from 'src/contexts';
import { useOrganizationDialogContext } from '../OrganizationProviders/OrganizationDialogProvider';

export const OrganizationAllOrgsList = () => {
    const { userOrganizations, organization } = useOrganizationContext();
    const { handleOrganizationClick } = useOrganizationDialogContext();

    return (
        <List disablePadding>
            {userOrganizations.map((org) => {
                const orgName = org.organizationName;

                return (
                    <DialogListItemActionButton
                        key={orgName}
                        selected={orgName === organization?.organizationName}
                        onListItemButtonClick={() => handleOrganizationClick(orgName)}
                    >
                        <ListItemIcon
                            sx={{
                                minWidth: '0',
                                marginRight: '16px',
                            }}
                        >
                            <DomainIcon />
                        </ListItemIcon>
                        <Typography variant='body1'>{orgName}</Typography>
                    </DialogListItemActionButton>
                );
            })}
        </List>
    );
};
