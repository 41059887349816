import { useOktaAuth } from '@okta/okta-react';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { RccEvents } from 'src/classes/event-emitter';
import { SnackbarContextProvider } from 'src/components/common/SnackBar/SnackBar';
import { OrganizationContextProvider } from 'src/components/organization/OrganizationProviders/OrganizationProvider';
import { ApigeeGateways, Global, GlobalEmitter, IDMUrlsByEnv } from 'src/constants/global';
import { IRccContext, RccContext } from 'src/contexts/RccContext';
import { useGetOktaUser } from 'src/fetchers/okta.fetchers';
import { RccContextConfig } from 'src/types';
import { localStorageProvider } from 'src/utils/local-storage.utils';
import { isInternalUser } from 'src/utils/user.utils';
import { SWRConfig } from 'swr';
import { OrganizationsCacheProvider } from '../organizations-cache/OrganizationsCacheProvider';
import { UserPreferencesProvider } from '../user-settings/preferences/UserPreferencesProvider';
import { RccCacheOperator } from './RccCacheOperator';
import { RccLanguageOperator } from './RccLanguageOperator';
import './RccStyles.css';
import UserProfileContextProvider from 'src/contexts/UserProfileContext';

const listener = new RccEvents(GlobalEmitter);

export const RccContextProvider = ({
    config,
    children,
}: {
    config: RccContextConfig;
    children: ReactNode;
}) => {
    const { authState } = useOktaAuth();
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
    const { userInfo, refreshUserInfo } = useGetOktaUser();

    Global.Services = ApigeeGateways[config.environment];
    Global.IdmUrl = IDMUrlsByEnv[config.environment];
    Global.Environment = config.environment;

    useEffect(() => {
        async function authenticate() {
            const accessToken = authState?.accessToken?.accessToken;
            const isAuthenticated = authState?.isAuthenticated && !!accessToken;
            Global.AccessToken = isAuthenticated ? accessToken : '';
            setIsAuthenticated(isAuthenticated);
        }

        authenticate();
    }, [authState, setIsAuthenticated]);

    const contextValue: IRccContext = useMemo(
        () => ({
            isAuthenticated,
            events: listener,
            isInternalUser: isInternalUser(userInfo),
            userInfo,
            _TEMPORARY_globalProfileOktaSdkEnabled: config?._TEMPORARY_globalProfileOktaSdkEnabled,
            refreshUserInfo,
        }),
        [
            isAuthenticated,
            userInfo,
            refreshUserInfo,
            config?._TEMPORARY_globalProfileOktaSdkEnabled,
        ],
    );

    return (
        <RccContext.Provider value={contextValue}>
            <SWRConfig
                value={{
                    provider: localStorageProvider,
                    keepPreviousData: true,
                    revalidateOnFocus: false,
                    errorRetryCount: 0,
                }}
            >
                <RccLanguageOperator language={config.language} />
                <OrganizationsCacheProvider>
                    <RccCacheOperator enableAutoLogout={config?.enableAutoLogout} />
                    <SnackbarContextProvider>
                        <UserPreferencesProvider>
                            <OrganizationContextProvider>
                                <UserProfileContextProvider>{children}</UserProfileContextProvider>
                            </OrganizationContextProvider>
                        </UserPreferencesProvider>
                    </SnackbarContextProvider>
                </OrganizationsCacheProvider>
            </SWRConfig>
        </RccContext.Provider>
    );
};
