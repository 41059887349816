import { RoomPreferences } from '@mui/icons-material';
import { SettingsPanelBox } from 'src/components/user-settings/common/SettingsPanel';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { DefaultOrganizationPreferences } from './DefaultOrganization/DefaultOrganizationPreferences';

export const DefaultPreferences = () => {
    const { t } = useTranslationNs({
        keyPrefix: 'USER_SETTINGS.PREFERENCES.DEFAULTS',
    });

    return (
        <SettingsPanelBox title={t('TITLE')} icon={<RoomPreferences />}>
            <DefaultOrganizationPreferences />
        </SettingsPanelBox>
    );
};
