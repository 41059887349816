import { UnfoldMore } from '@mui/icons-material';
import LoadingButton, { LoadingButtonProps } from '@mui/lab/LoadingButton/LoadingButton';
import { Box, Tooltip, useTheme, SxProps, Theme } from '@mui/material';
import { ForwardedRef, ReactNode, forwardRef } from 'react';
import { ThemeValues } from 'src/constants/theme-values';
import { useSwitcherContext } from 'src/contexts/SwitcherContext';

export type SwitcherProps = {
    children: ReactNode;
    tooltipTitle: string;
    enableSwitcher?: boolean;
    isLoading: boolean;
    onClick: () => void;
    startIcon?: ReactNode;
    endIcon?: ReactNode;
    disabled?: boolean;
    loadingButtonSx?: SxProps<Theme>;
    labelSx?: SxProps<Theme>;
};

export const Switcher = forwardRef<HTMLButtonElement, SwitcherProps>(
    (
        {
            children,
            disabled,
            enableSwitcher = true,
            tooltipTitle,
            isLoading,
            onClick,
            loadingButtonSx = {},
            labelSx = {},
            startIcon,
            endIcon = (
                <UnfoldMore
                    fontSize='small'
                    sx={{
                        color: (theme) => theme.palette.action.active,
                        height: '24px',
                        width: '24px',
                    }}
                />
            ),
            ...rest
        }: SwitcherProps,
        ref,
    ) => {
        const theme = useTheme();
        const iconColor = disabled || isLoading ? 'inherit' : theme.palette.action.active;
        const { showStartIconOnly, isDisabled } = useSwitcherContext();
        const disableSwitcher = (disabled ?? isLoading) || isDisabled;
        const startIconProps: Pick<LoadingButtonProps, 'startIcon' | 'loadingPosition'> = {
            startIcon: startIcon,
            loadingPosition: 'start',
        };
        const startIconLoadingStyle: SxProps = isLoading
            ? {
                  height: '24px',
                  width: '24px',
                  flexShrink: 0,
                  overflow: 'hidden',
              }
            : {};

        return (
            <Tooltip title={tooltipTitle}>
                <span>
                    <LoadingButton
                        ref={ref as ForwardedRef<HTMLButtonElement>}
                        loading={isLoading && !!startIcon}
                        onClick={onClick}
                        variant='text'
                        endIcon={
                            (isLoading || enableSwitcher) && !showStartIconOnly ? endIcon : null
                        }
                        sx={{
                            columnGap: 1,
                            borderRadius: ThemeValues.BorderRadius,
                            textTransform: 'none',
                            color: theme.palette.text.primary,
                            padding: '6px',
                            '.MuiButton-endIcon, .MuiButton-startIcon': {
                                marginLeft: 0,
                                marginRight: 0,
                                padding: '2px',
                            },
                            '.MuiButton-endIcon svg, .MuiButton-startIcon svg': {
                                marginRight: '-0.5px',
                                color: iconColor,
                            },
                            '.MuiButton-startIcon': {
                                color: iconColor,
                                ...startIconLoadingStyle,
                            },
                            '&.Mui-disabled': {
                                '& > #switcher-label': {
                                    fontWeight: 500,
                                },
                            },
                            minWidth: '24px',
                            maxWidth: '224px',
                            ...loadingButtonSx,
                        }}
                        disabled={disableSwitcher || isLoading}
                        {...(!!startIcon ? startIconProps : {})}
                        {...rest}
                    >
                        {!showStartIconOnly && (
                            <Box
                                id='switcher-label'
                                sx={{
                                    ...theme.typography.subtitle1,
                                    textOverflow: 'ellipsis',
                                    textAlign: 'left',
                                    whiteSpace: 'nowrap',
                                    width: '100%',
                                    overflow: 'hidden',
                                    color: 'inherit',
                                    fontWeight: 600,
                                    ...labelSx,
                                }}
                                aria-live='assertive'
                                aria-atomic='true'
                            >
                                {children}
                            </Box>
                        )}
                    </LoadingButton>
                </span>
            </Tooltip>
        );
    },
);
