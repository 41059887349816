import { z } from 'zod';
import { errorHelperTexts } from 'src/utils/form.utils';

export type BasicInfoFormProps = {
    givenName: string;
    familyName: string;
    dateOfBirth: string | null | undefined;
    telephoneNumber: string | null | undefined;
};

export interface UserAddressFormProps {
    city: string | null | undefined;
    country: string | null | undefined;
    postalCode: string | null | undefined;
    state: string | null | undefined;
    street: string | null | undefined;
    addressLine2: string | null | undefined;
    county: string | null | undefined;
}

export const UserAddressSchema = z.object({
    city: z
        .string()
        .max(128)
        .regex(/[a-zA-Z][a-zA-Z\d'-. ,]*/)
        .or(z.literal('').transform(() => null))
        .nullable()
        .optional(),
    country: z
        .string()
        .max(128)
        .regex(/[a-zA-Z][a-zA-Z\d'-. ,]*/)
        .or(z.literal('').transform(() => null))
        .nullable()
        .optional(),
    postalCode: z
        .string()
        .max(64)
        .min(1)
        .regex(/[a-zA-Z0-9-]*/)
        .or(z.literal('').transform(() => null))
        .nullable()
        .optional(),
    state: z
        .string()
        .max(128)
        .regex(/[a-zA-Z][a-zA-Z\d'-. ,]*/)
        .or(z.literal('').transform(() => null))
        .nullable()
        .optional(),
    street: z
        .string()
        .max(128)
        .min(1)
        .or(z.literal('').transform(() => null))
        .nullable()
        .optional(),
    addressLine2: z
        .string()
        .max(200)
        .min(1)
        .or(z.literal('').transform(() => null))
        .nullable()
        .optional(),
    county: z
        .string()
        .max(128)
        .min(1)
        .or(z.literal('').transform(() => null))
        .nullable()
        .optional(),
});

export const BasicInfoSchema = z.object({
    givenName: z.string().max(512).min(1),
    familyName: z.string().max(512).min(1),
    dateOfBirth: z
        .string()
        .refine((val) => !isNaN(Date.parse(val)), { message: errorHelperTexts.date_format })
        .nullable()
        .optional(),
    telephoneNumber: z
        .string()
        .max(32)
        .regex(/\+?(\d|\()[\d-() ]*\d/)
        .or(z.literal('').transform(() => null))
        .nullable()
        .optional(),
});
