import Grid from '@mui/material/Grid';
import { useCallback, useEffect, useState } from 'react';
import { FormHelperText, Stack, useTheme } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { BasicInfoFormProps, BasicInfoSchema } from './profile-form.schema';
import { zodResolver } from '@hookform/resolvers/zod';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import { AxiosError } from 'axios';
import { SettingsPanel } from 'src/components/user-settings/common/SettingsPanel';
import { useSnackbarContext } from 'src/components/common/SnackBar/SnackBar';
import { isFederated } from 'src/types';
import TextFieldControlledInput, {
    ReadOnlyField,
} from 'src/components/common/ControlledInput/TextFieldControlledInput';
import { AppConstants } from 'src/constants/app';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { ThemeValues } from 'src/constants/theme-values';
import { useUserProfileContext } from 'src/contexts/UserProfileContext';
import { MuiTelInput } from 'mui-tel-input';

const BasicInfoForm = () => {
    const [editMode, setEditMode] = useState(false);

    const { t } = useTranslationNs({
        keyPrefix: 'USER_SETTINGS.PROFILE',
    });

    const { t: commonTranslation } = useTranslationNs({});

    const { userProfile, refreshUserProfile, updateUserProfile, userProfileLoading } =
        useUserProfileContext();

    const isUserFederated = useCallback(() => {
        return isFederated(userProfile);
    }, [userProfile]);

    const theme = useTheme();

    const { setSnackOptions } = useSnackbarContext();

    const {
        handleSubmit,
        control,
        reset,
        formState: { errors, isDirty, isSubmitting, isValidating },
    } = useForm<BasicInfoFormProps>({
        defaultValues: {
            givenName: userProfile?.givenName,
            familyName: userProfile?.familyName,
            dateOfBirth: userProfile?.dateOfBirth,
            telephoneNumber: userProfile?.telephoneNumber,
        },
        resolver: zodResolver(BasicInfoSchema),
    });

    const resetForm = () => {
        reset({
            givenName: userProfile?.givenName,
            familyName: userProfile?.familyName,
            dateOfBirth: userProfile?.dateOfBirth,
            telephoneNumber: userProfile?.telephoneNumber,
        });
        setEditMode(false);
    };

    useEffect(() => {
        resetForm();
    }, [userProfile]);

    const onSubmit: SubmitHandler<BasicInfoFormProps> = (formData) => {
        if (!userProfile) {
            return;
        }

        formData.dateOfBirth = moment(formData.dateOfBirth).format(AppConstants.YearMonthDayFormat);
        console.log('formData.dateOfBirth', formData.dateOfBirth);

        const updatedData = { ...userProfile, ...formData };

        return updateUserProfile(updatedData)
            .then(() => {
                refreshUserProfile();
            })
            .catch((error: AxiosError) => {
                setSnackOptions({
                    open: true,
                    color: 'error',
                    message: error.message,
                });
            })
            .finally(() => {
                setEditMode(false);
            });
    };

    const loading = useCallback(() => {
        return userProfileLoading || !userProfile || isSubmitting || isValidating;
    }, [userProfileLoading, userProfile, isSubmitting, isValidating]);

    return (
        <SettingsPanel
            onSave={handleSubmit(onSubmit)}
            onCancel={() => resetForm()}
            editMode={editMode}
            setEditMode={setEditMode}
            title={t('BASIC_INFO')}
            icon={<PersonIcon />}
            loadingButton={loading()}
            cancelDisabled={loading()}
            disabled={!isDirty}
        >
            {userProfile && (
                <form onSubmit={handleSubmit(onSubmit)}>
                    {editMode ? (
                        <Grid
                            container
                            spacing={2}
                            sx={{
                                textAlign: 'left',
                            }}
                        >
                            <Grid
                                item
                                sx={{
                                    [theme.breakpoints.down(ThemeValues.Breakpoints.sm)]: {
                                        flexBasis: '100%',
                                    },
                                    [theme.breakpoints.up(ThemeValues.Breakpoints.sm)]: {
                                        flexBasis: '50%',
                                    },
                                }}
                            >
                                <TextFieldControlledInput
                                    control={control}
                                    name='givenName'
                                    required
                                    errors={errors}
                                    disabled={loading() || isUserFederated()}
                                    fullWidth
                                    label={t('FIRST_NAME')}
                                />
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    [theme.breakpoints.down(ThemeValues.Breakpoints.sm)]: {
                                        flexBasis: '100%',
                                    },
                                    [theme.breakpoints.up(ThemeValues.Breakpoints.sm)]: {
                                        flexBasis: '50%',
                                    },
                                }}
                            >
                                <TextFieldControlledInput
                                    control={control}
                                    name='familyName'
                                    required
                                    errors={errors}
                                    disabled={loading() || isUserFederated()}
                                    fullWidth
                                    label={t('LAST_NAME')}
                                />
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    flexBasis: '100%',
                                }}
                            >
                                <Controller
                                    control={control}
                                    name='dateOfBirth'
                                    render={({ field }) => (
                                        <>
                                            <DatePicker
                                                {...field}
                                                value={field.value ? moment(field.value) : null}
                                                onChange={(date) =>
                                                    field.onChange(
                                                        date
                                                            ? date.format(
                                                                  AppConstants.MonthDayYearFormat,
                                                              )
                                                            : null,
                                                    )
                                                }
                                                openTo={'year'}
                                                views={['year', 'month', 'day']}
                                                minDate={moment('1900-01-01')}
                                                maxDate={moment(moment.now())}
                                                format={AppConstants.MonthDayYearFormat}
                                                label={t('DOB')}
                                                sx={{
                                                    width: '100%',
                                                }}
                                                disabled={loading()}
                                            />
                                            {errors.dateOfBirth && (
                                                <FormHelperText>
                                                    {commonTranslation(errors.dateOfBirth.message)}
                                                </FormHelperText>
                                            )}
                                        </>
                                    )}
                                />
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    flexBasis: '100%',
                                }}
                            >
                                <Controller
                                    control={control}
                                    name='telephoneNumber'
                                    render={({ field }) => (
                                        <>
                                            <MuiTelInput
                                                fullWidth
                                                label={t('PHONE_NUMBER')}
                                                disabled={loading()}
                                                {...field}
                                            />
                                            {errors.telephoneNumber?.message && (
                                                <FormHelperText>
                                                    {commonTranslation(
                                                        errors.telephoneNumber.message,
                                                    )}
                                                </FormHelperText>
                                            )}
                                        </>
                                    )}
                                />
                            </Grid>
                        </Grid>
                    ) : (
                        <Stack gap={2}>
                            <ReadOnlyField value={userProfile.givenName} label={t('FIRST_NAME')} />
                            <ReadOnlyField value={userProfile.familyName} label={t('LAST_NAME')} />
                            <ReadOnlyField
                                label={t('DOB')}
                                value={
                                    userProfile.dateOfBirth
                                        ? moment(userProfile.dateOfBirth).format(
                                              AppConstants.MonthDayYearFormat,
                                          )
                                        : null
                                }
                            />
                            <ReadOnlyField
                                value={userProfile.telephoneNumber}
                                label={t('PHONE_NUMBER')}
                            />
                        </Stack>
                    )}
                </form>
            )}
        </SettingsPanel>
    );
};

export default BasicInfoForm;
