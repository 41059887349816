import LogoutPrompt from 'src/components/user-settings/profile/info/LogoutPrompt/LogoutPrompt';
import { SettingsTabContainer } from 'src/components/user-settings/common/SettingContainer';
import EmailField from 'src/components/user-settings/profile/info/Email/EmailField';
import { useRccContext } from 'src/contexts';
import { UserProfileV2 } from 'src/components/user-settings/profile/UserProfileV2';
import PersonalInfo from 'src/components/user-settings/profile/info/PersonalInfo/PersonalInfo';

export const UserProfile = () => {
    const { isInternalUser } = useRccContext();

    const { _TEMPORARY_globalProfileOktaSdkEnabled } = useRccContext();

    if (!_TEMPORARY_globalProfileOktaSdkEnabled) {
        return <UserProfileV2 />;
    }

    return (
        <SettingsTabContainer>
            <PersonalInfo />
            {(!isInternalUser || true) && <EmailField />}
            <LogoutPrompt />
        </SettingsTabContainer>
    );
};
