import { Close } from '@mui/icons-material';
import { Box, IconButton, Modal, Stack, Theme, Typography } from '@mui/material';
import { useCallback, useEffect, useRef, useState } from 'react';
import { ThemeValues } from 'src/constants/theme-values';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { UserPreferences } from './preferences/UserPreferences';
import { UserProfile } from 'src/components/user-settings/profile/UserProfile';
import { UserSecurity } from 'src/components/user-settings/security/UserSecurity';
import { useRccContext } from 'src/contexts';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import TuneIcon from '@mui/icons-material/Tune';
import PrivacyTipOutlinedIcon from '@mui/icons-material/PrivacyTipOutlined';
import useDesktopView from 'src/hooks/useDesktopView';
import Slide from '@mui/material/Slide';
import BouncyScrollView from 'src/components/common/BouncyScrollView/BouncyScrollView';
import Fade from '@mui/material/Fade';
import { UserSettingsTabData } from 'src/components/user-settings/user-settings-navigation';
import TabPanel from 'src/components/common/TabPanel/TabPanel';
import UserSettingsModalHeader from 'src/components/user-settings/UserSettingsModalHeader';
import UserSettingsModalSidenav from 'src/components/user-settings/UserSettingsModalSidenav';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers';

const ModalHeight = '100vh';

export const UserSettingsModal = ({
    isOpen,
    onClose,
}: {
    isOpen: boolean;
    onClose: () => void;
}) => {
    const { t } = useTranslationNs({
        keyPrefix: 'USER_SETTINGS',
    });

    const tabContentContainerRef = useRef<HTMLDivElement>(null);

    const [activeTabIndex, setActiveTabIndex] = useState(0);
    const [prevTabIndex, setPrevTabIndex] = useState(activeTabIndex);
    const [isScrolled, setIsScrolled] = useState(false);

    const getSlideDirection = useCallback(() => {
        return activeTabIndex > prevTabIndex ? 'left' : 'right';
    }, [activeTabIndex, prevTabIndex]);

    useEffect(() => {
        setPrevTabIndex(activeTabIndex);
    }, [activeTabIndex]);

    useEffect(
        function resetTabPosition() {
            if (!isOpen) {
                setActiveTabIndex(0);
                setIsScrolled(false);
                if (tabContentContainerRef.current) {
                    tabContentContainerRef.current.scrollTop = 0;
                }
            }
        },
        [isOpen],
    );

    useEffect(() => {
        const handleScroll = () => {
            if (tabContentContainerRef.current) {
                setIsScrolled(tabContentContainerRef.current.scrollTop > 0);
            }
        };

        if (tabContentContainerRef.current) {
            tabContentContainerRef.current.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (tabContentContainerRef.current) {
                tabContentContainerRef.current.removeEventListener('scroll', handleScroll);
            }
        };
    }, [isOpen]);

    const scrollToTop = useCallback(() => {
        if (tabContentContainerRef.current) {
            const scrollCushion = 10;
            tabContentContainerRef.current.scrollTop = isScrolled ? scrollCushion : 0;
        }
    }, [tabContentContainerRef, isScrolled]);

    const { isInternalUser } = useRccContext();

    let tabs: UserSettingsTabData[] = [
        {
            title: t('PROFILE.TITLE'),
            description: t('PROFILE.DESCRIPTION'),
            component: <UserProfile />,
            icon: <AccountCircleOutlinedIcon />,
        },
        {
            title: t('PREFERENCES.TITLE'),
            description: t('PREFERENCES.DESCRIPTION'),
            component: <UserPreferences />,
            icon: <TuneIcon />,
        },
    ];

    if (!isInternalUser) {
        tabs = tabs.concat({
            title: t('SECURITY.TITLE'),
            description: t('SECURITY.DESCRIPTION'),
            component: <UserSecurity />,
            icon: <PrivacyTipOutlinedIcon />,
        });
    }

    const handleTabChange = (_, newValue: number) => {
        setActiveTabIndex(newValue);
        scrollToTop();
    };

    const isDesktopView = useDesktopView();

    const mobileSxOverrides = {
        width: '100%',
        height: '100%',
        borderRadius: 0,
    };

    const desktopSxOverrides = {
        width: 'calc(100vw - 64px)',
        height: 'calc(100vh - 64px)',
    };

    return (
        <Modal onClose={onClose} open={isOpen} keepMounted>
            <Box>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Box
                        sx={{
                            position: 'absolute' as 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '65vw',
                            height: ModalHeight,
                            borderRadius: '16px',
                            backgroundColor: (theme: Theme) => theme.palette.background.paper,
                            overflowY: 'hidden',
                            ...(isDesktopView ? desktopSxOverrides : mobileSxOverrides),
                        }}
                    >
                        <IconButton
                            onClick={onClose}
                            title={t('CLOSE_BUTTON_TITLE')}
                            sx={{
                                position: 'absolute',
                                top: '12px',
                                right: '16px',
                            }}
                        >
                            <Close />
                        </IconButton>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: isDesktopView ? 'row' : 'column',
                                height: '100%',
                            }}
                        >
                            {isDesktopView ? (
                                <UserSettingsModalSidenav
                                    tabs={tabs}
                                    activeTabIndex={activeTabIndex}
                                    handleTabChange={handleTabChange}
                                />
                            ) : (
                                <UserSettingsModalHeader
                                    tabs={tabs}
                                    activeTabIndex={activeTabIndex}
                                    handleTabChange={handleTabChange}
                                    collapsed={isScrolled}
                                />
                            )}
                            <Box
                                sx={{
                                    flex: 1,
                                    overflowY: 'auto',
                                    height: 'auto',
                                    scrollbarGutter: 'stable',
                                }}
                                ref={tabContentContainerRef}
                            >
                                <BouncyScrollView>
                                    {tabs.map((td: UserSettingsTabData, tabNumber: number) => (
                                        <TabPanel
                                            key={td.title}
                                            value={activeTabIndex}
                                            index={tabNumber}
                                            tabPadding={ThemeValues.UserSettingsPadding}
                                            a11yTabPrefix='account-tab'
                                            a11yTabPanelPrefix='account-tabpanel'
                                        >
                                            <Slide
                                                in={activeTabIndex === tabNumber}
                                                direction={getSlideDirection()}
                                                appear={
                                                    prevTabIndex !== tabNumber && !isDesktopView
                                                }
                                                timeout={300}
                                            >
                                                <Fade
                                                    in={activeTabIndex === tabNumber}
                                                    appear={
                                                        prevTabIndex !== tabNumber && isDesktopView
                                                    }
                                                    timeout={500}
                                                >
                                                    <Box>
                                                        <Stack
                                                            mb='32px'
                                                            mt={isDesktopView ? '32px' : '0px'}
                                                        >
                                                            <Typography
                                                                fontSize='25px'
                                                                fontWeight='600'
                                                            >
                                                                {td.title}
                                                            </Typography>
                                                            <Typography
                                                                fontSize='16px'
                                                                fontWeight='400'
                                                                color='text.secondary'
                                                            >
                                                                {td.description}
                                                            </Typography>
                                                        </Stack>
                                                        {td.component}
                                                    </Box>
                                                </Fade>
                                            </Slide>
                                        </TabPanel>
                                    ))}
                                </BouncyScrollView>
                            </Box>
                        </div>
                    </Box>
                </LocalizationProvider>
            </Box>
        </Modal>
    );
};
