import { ReactNode } from 'react';
import { AppConstants } from 'src/constants/app';
import { useOrganizationContext } from 'src/contexts';
import { SearchOrganizationPreferenceSwitcher } from './SearchOrganizationPreferenceSwitcher';
import { OrganizationPreferenceSwitcher } from './OrganizationPreferenceSwitcher';
import { SettingInputLabel } from 'src/components/user-settings/common/SettingInputLabel';
import { useTranslationNs } from 'src/hooks/useTranslationNs';

export const DefaultOrganizationPreferences = () => {
    const { t } = useTranslationNs({
        keyPrefix: 'USER_SETTINGS.PREFERENCES.DEFAULTS.ORGANIZATION',
    });
    const { totalOrganizations } = useOrganizationContext();
    const enableSearch = totalOrganizations > AppConstants.EffectiveOrgsLimit;
    let switcher: ReactNode;

    if (enableSearch) {
        switcher = <SearchOrganizationPreferenceSwitcher />;
    } else {
        switcher = <OrganizationPreferenceSwitcher />;
    }

    return (
        <SettingInputLabel title={t('TITLE')} description={t('DESCRIPTION')}>
            {switcher}
        </SettingInputLabel>
    );
};
