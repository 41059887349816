import { PopoverOrigin } from '@mui/material';
import { createContext, useContext } from 'react';

export type SwitcherOptions = {
    menuAnchorOrigin?: PopoverOrigin;
    menuTransformOrigin?: PopoverOrigin;
    showStartIconOnly?: boolean;
    isDisabled?: boolean;
};

export const SwitcherContext = createContext<SwitcherOptions>({} as SwitcherOptions);

export const useSwitcherContext = () => useContext(SwitcherContext);
