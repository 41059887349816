import { Box, Stack, SxProps, Theme, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { SwitcherSkeleton } from 'src/components/common/Switcher/SwitcherSkeleton';
import { ThemeValues } from 'src/constants/theme-values';
import { useRccContext } from 'src/contexts';
import useMobileView from 'src/hooks/useMobileView';

const generateMobileStyles = (isMobile: boolean) => {
    return isMobile
        ? {
              flexDirection: 'column',
              rowGap: '16px',
          }
        : {
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              columnGap: '16px',
          };
};

export const SettingGroup = ({
    children,
    title,
    description,
}: {
    children: ReactNode;
    title: string;
    description?: string;
}) => {
    const { isInternalUser } = useRccContext();
    const isMobile = useMobileView();
    const mobileStyles: SxProps<Theme> = generateMobileStyles(isMobile);

    return (
        <Box border='1px solid' borderColor='divider' borderRadius='12px' padding='16px'>
            <Stack sx={mobileStyles}>
                <Stack rowGap='3px' fontSize='16px'>
                    <Typography variant='h5' fontWeight={600} fontSize={'inherit'}>
                        {title}
                    </Typography>
                    <Typography color='text.secondary' component='p' fontSize={'inherit'}>
                        {description}
                    </Typography>
                </Stack>

                {isInternalUser !== undefined ? (
                    children
                ) : (
                    <SwitcherSkeleton width={ThemeValues.UserConfigSwitcherWidth} />
                )}
            </Stack>
        </Box>
    );
};
