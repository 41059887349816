import { useCallback, useEffect, useRef, useState } from 'react';
import { AppConstants } from 'src/constants/app';
import { DefaultUserPreferences } from 'src/constants/defaults';
import { useRccContext } from 'src/contexts';
import { ApiError } from 'src/types';
import { SwrStaticKeys } from 'src/types/swr-keys.type';
import { UpdateUserPreferences, UserPreferences } from 'src/types/user-preferences.type';
import { DefaultErrorHandler } from 'src/utils/services.utils';
import useSWR from 'swr';
import { Global } from '../constants/global';
import { get, put } from './common.fetchers';
import { isDeepEqual } from 'src/utils/deep-equal.utils';

const baseUrl = () => `${Global.Services.FrontDoor}/user-preferences/self`;

const fetchUserPreferences = () => {
    return get<UserPreferences>(`${baseUrl()}`, {
        headers: {
            [AppConstants.OrgHeader]: null
        }
    }).catch(DefaultErrorHandler);
}

export const putUserPreferences = (payload: UpdateUserPreferences): Promise<UserPreferences> => {
    return put<UserPreferences, UpdateUserPreferences>(`${baseUrl()}`, payload, {
        headers: {
            [AppConstants.OrgHeader]: null
        }
    })
}

const detectIsEqual = (previous: UserPreferences, updated: UserPreferences): boolean => {
    return isDeepEqual(previous, updated);
}

export const useGetUserPreferences = () => {
    const { isAuthenticated } = useRccContext();
    const [isUserPreferencesMutating, setIsUserPreferencesMutating] = useState<boolean>(false);
    const [userPreferencesMutationError, setUserPreferencesMutationError] = useState<ApiError>();
    const preferencesSnapshot = useRef<UserPreferences>();
    const { data: userPreferences, isValidating: isUserPreferencesValidating, isLoading: isUserPreferencesLoading, error: getUserPreferencesError, mutate } = useSWR<UserPreferences>(
        isAuthenticated ? SwrStaticKeys.USER_PREFERENCES : null, fetchUserPreferences, {
        fallbackData: DefaultUserPreferences
    }
    );

    useEffect(() => {
        preferencesSnapshot.current = userPreferences;
    }, [userPreferences]);


    const updateUserPreferences = useCallback(
        async (updatePayload: UpdateUserPreferences) => {
            const existing = { ...preferencesSnapshot.current };
            const payload: UserPreferences = Object.assign(existing, updatePayload);

            if (detectIsEqual(preferencesSnapshot.current, payload)) {
                return;
            }

            const { username, ...updates } = payload;
            const overrides: UpdateUserPreferences = updates;
            try {
                setIsUserPreferencesMutating(true);
                await putUserPreferences(overrides).catch(DefaultErrorHandler);
                await mutate(payload, { revalidate: false })
            } catch (e: unknown) {
                setUserPreferencesMutationError(e as ApiError);
            } finally {
                setIsUserPreferencesMutating(false);
            }
        },
        [setIsUserPreferencesMutating, mutate],
    );

    return {
        userPreferences,
        isUserPreferencesLoading,
        isUserPreferencesValidating,
        getUserPreferencesError,
        isUserPreferencesMutating,
        userPreferencesMutationError,
        updateUserPreferences
    }
}