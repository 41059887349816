import { Dispatch, SetStateAction, createContext, useContext } from 'react';
import { ApiError, SharedApplicationModel } from '..';
import { ApplicationGrantModel, CoreApplicationModel } from 'src/types/application.type';

export type IApplicationsContextInternal = {
    isLoading: boolean;
    isAppDialogOpen: boolean;
    setIsAppDialogOpen: Dispatch<SetStateAction<boolean>>;
    getSwitcherList: () => ApplicationGrantModel[] | CoreApplicationModel[];
    error: ApiError | null;
    application: SharedApplicationModel | undefined;
    userApplications: SharedApplicationModel[];
    allApplications: SharedApplicationModel[];
};

export type IApplicationsContext = Omit<
    IApplicationsContextInternal,
    'allApplications' | 'getSwitcherList'
>;

export const ApplicationsContext = createContext<IApplicationsContextInternal>(
    {} as IApplicationsContextInternal,
);

export const useApplicationsContextInternal = () => useContext(ApplicationsContext);

export const useApplicationsContext = (): IApplicationsContext => {
    const { isLoading, isAppDialogOpen, setIsAppDialogOpen, error, application, userApplications } =
        useContext(ApplicationsContext);

    return {
        isLoading,
        isAppDialogOpen,
        setIsAppDialogOpen,
        error,
        application,
        userApplications,
    };
};
