import { AppConstants } from 'src/constants/app';

export const enum formError {
    required = 'required',
    invalidFormat_email = 'invalidFormat_email',
    qlidFormat_email = 'qlidFormat_email',
    requiredFirstName = 'requiredFirstName',
    requiredFirstNameForSave = 'requiredFirstNameForSave',
    requiredLastName = 'requiredLastName',
    requiredLastNameForSave = 'requiredLastNameForSave',
    requiredEmail = 'requiredEmail',
    notNcrEmailFormat = 'notNcrEmailFormat',
}

export interface ErrorInterface {}

export const errorHelperTexts = {
    required: 'COMMON.FORM.ERRORS.REQUIRED',
    invalidFormat_email: 'COMMON.FORM.ERRORS.EMAIL_INVALID',
    qlidFormat_email: 'COMMON.FORM.ERRORS.INTERNAL_EMAIL_INVALID',
    requiredFirstName: 'COMMON.FORM.ERRORS.REQUIRED_FIRST_NAME',
    requiredFirstNameForSave: 'COMMON.FORM.ERRORS.REQUIRED_FIRST_NAME_FOR_SAVE',
    requiredLastName: 'COMMON.FORM.ERRORS.REQUIRED_LAST_NAME',
    requiredLastNameForSave: 'COMMON.FORM.ERRORS.REQUIRED_LAST_NAME_FOR_SAVE',
    requiredEmail: 'COMMON.FORM.ERRORS.REQUIRED_EMAIL',
    notNcrEmailFormat: 'COMMON.FORM.ERRORS.NCR_EMAIL_PROHIBITED',
    date_format: 'COMMON.FORM.ERRORS.DATE_FORMAT',
};

export const updateValue = (value: any, name: string, values: {}, setValues: any) => {
    setValues({ ...values, [name]: value });
};

export const validateRequired = (value: string, name: string, errors: {}, setErrors: any) => {
    const onlySpaces = (str: string) => str.trim().length > 0;
    setErrors({ ...errors, [name]: onlySpaces(value) ? null : 'required' });
};

export const validateEmailFormat = (
    value: string,
    name: string,
    errors: {},
    setErrors: any,
    enforceQLIDFormat: boolean = false,
    prohibitNcrEmail: boolean = false,
) => {
    if (!value) return;

    const isEmail = (email: string) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

    if (!isEmail(value)) {
        setErrors({ ...errors, [name]: 'invalidFormat_email' });
        return;
    }

    if (enforceQLIDFormat) {
        const isValidNcrEmail = validateNcrEmailFormat(value);
        setErrors({ ...errors, [name]: isValidNcrEmail ? null : 'qlidFormat_email' });
    }

    if (prohibitNcrEmail) {
        const isNcrEmail = isInternalEmail(value);
        setErrors({ ...errors, [name]: !isNcrEmail ? null : 'notNcrEmailFormat' });
    }
};

export const isInternalEmail = (email: string) =>
    email.endsWith(AppConstants.NcrEmailFormat) || email.endsWith(AppConstants.NcrVoyixEmailFormat);

const validateNcrEmailFormat = (value: string): boolean => {
    if (!value) return true;

    const isQlidEmailFormat = (email: string) => {
        return /^[A-Za-z]{2}\d{6}@ncr\.com/i.test(email);
    };

    return !isInternalEmail(value) || isQlidEmailFormat(value);
};

export const hasErrors = <T extends ErrorInterface>(errors: T): boolean => {
    for (let field in errors) {
        if (!errors.hasOwnProperty(field)) continue;
        if (errors[field] !== null) {
            return true;
        }
    }
    return false;
};

export {};
