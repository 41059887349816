import { Box, SxProps } from '@mui/material';
import { MutableRefObject, ReactNode, useRef, useState } from 'react';
import HierarchyIcon from 'src/components/common/Icons/Hierarchy';
import RccBoundary from 'src/components/common/RccBoundary/RccBoundary';
import { Switcher } from 'src/components/common/Switcher/Switcher';
import {
    SwitcherProvider,
    getDefaultSwitcherConfig,
} from 'src/components/common/Switcher/SwitcherProvider';
import { SwitcherOptions, useOrganizationContext } from 'src/contexts';
import { useOrganizationsCacheContextInternal } from 'src/contexts/OrganizationsCacheContext';
import useMobileView from 'src/hooks/useMobileView';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { OrganizationSwitcherDialog } from '../OrganizationSwitcherDialog/OrganizationSwitcherDialog';
import { OrganizationSwitcherDrawer } from './OrganizationSwitcherDrawer';
import { OrganizationSwitcherMenu } from './OrganizationSwitcherMenu';

export const OrganizationSwitcher = ({
    config = getDefaultSwitcherConfig(),
}: {
    config?: SwitcherOptions;
}) => {
    const { t } = useTranslationNs();
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const {
        hasPreloaded,
        isLoading: isLoadingProvider,
        isLoadingOrg,
        organization,
        userOrganizations,
        updateOrganization,
    } = useOrganizationContext();
    const buttonRef: MutableRefObject<HTMLButtonElement | null> = useRef(null);
    const { isRecentOrganizationsLoading, isRecentOrganizationsMutating } =
        useOrganizationsCacheContextInternal();
    const isMobile = useMobileView();
    const isLoadingRecents = isRecentOrganizationsMutating || isRecentOrganizationsLoading;
    const shouldOverrideDisabled =
        userOrganizations.length > 0 && !isLoadingRecents && !isLoadingProvider;
    const overrideStyles: SxProps = shouldOverrideDisabled
        ? {
              color: 'text.primary',
              fontWeight: 600,
          }
        : {};
    const hasNoOrgs = !isLoadingProvider && userOrganizations.length === 0;

    const handleOpenOrgMenu = () => {
        setMenuOpen(true);
    };

    const handleCloseOrgMenu = () => {
        setMenuOpen(false);
    };

    const handleOrgClick = (orgName: string) => {
        handleCloseOrgMenu();
        updateOrganization(orgName);
    };

    let switcherLabel: ReactNode;
    if (hasNoOrgs) {
        switcherLabel = t('ORG_CONTEXT.SWITCHER.EMPTY');
    } else if (!hasPreloaded || isLoadingOrg) {
        switcherLabel = t('COMMON.LOADING');
    } else if (!!organization) {
        switcherLabel = organization?.organizationName;
    } else {
        switcherLabel = t('ORG_CONTEXT.SWITCHER.SELECT');
    }

    return (
        <RccBoundary>
            <SwitcherProvider config={config || {}}>
                <Switcher
                    tooltipTitle={!hasNoOrgs ? switcherLabel.toString() : ''}
                    disabled={isLoadingProvider || userOrganizations.length <= 1}
                    enableSwitcher={userOrganizations.length > 1}
                    isLoading={(!hasPreloaded && !hasNoOrgs) || isLoadingOrg}
                    onClick={handleOpenOrgMenu}
                    startIcon={<HierarchyIcon />}
                    ref={buttonRef}
                    data-element-id='org-switcher-nav-button'
                >
                    <Box sx={overrideStyles} component='span'>
                        {switcherLabel}
                    </Box>
                </Switcher>
                {isMobile ? (
                    <OrganizationSwitcherDrawer
                        menuOpen={menuOpen}
                        onMenuOpen={handleOpenOrgMenu}
                        onMenuClose={handleCloseOrgMenu}
                        onOrgClick={handleOrgClick}
                    />
                ) : (
                    <OrganizationSwitcherMenu
                        anchorRef={buttonRef}
                        menuOpen={menuOpen}
                        onMenuClose={handleCloseOrgMenu}
                        onOrgClick={handleOrgClick}
                    />
                )}
                <OrganizationSwitcherDialog />
            </SwitcherProvider>
        </RccBoundary>
    );
};
