import { Alert, Box, Button, Link, Typography } from '@mui/material';
import { useRccContext } from 'src/contexts';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { SettingField } from 'src/components/user-settings/common/SettingContainer';
import { useState } from 'react';
import EmailIcon from '@mui/icons-material/Email';
import { SettingsPanelBox } from 'src/components/user-settings/common/SettingsPanel';
import UpdateEmailModal from 'src/components/user-settings/profile/info/Email/UpdateEmailModal/UpdateEmail/UpdateEmailModal';
import { Trans } from 'react-i18next';
import { getEmail } from 'src/utils/user.utils';
import Skeleton from '@mui/material/Skeleton';

const EmailField = () => {
    const { userInfo, isInternalUser } = useRccContext();
    const { t } = useTranslationNs({
        keyPrefix: 'USER_SETTINGS.PROFILE',
    });
    const [emailDialogOpen, setEmailDialogOpen] = useState<boolean>(false);

    function handleLeave() {
        setEmailDialogOpen(false);
    }

    if (!userInfo) {
        return <Skeleton variant='rounded' height={160} />;
    }

    return (
        <SettingsPanelBox title={t('EMAIL')} icon={<EmailIcon />}>
            {!userInfo.email_verified && (
                <Alert severity='error'>
                    <Box
                        display='flex'
                        justifyContent='space-between'
                        gap='8px'
                        alignItems='center'
                    >
                        <Typography>
                            <Trans
                                t={t}
                                i18nKey={'EMAIL_UPDATE.VERIFY_ALERT'}
                                components={[
                                    <Link
                                        onClick={() => {
                                            setEmailDialogOpen(true);
                                        }}
                                        sx={{
                                            cursor: 'pointer',
                                        }}
                                        type='button'
                                    />,
                                ]}
                            />
                        </Typography>
                    </Box>
                </Alert>
            )}

            <SettingField name={t('EMAIL')} description={getEmail(userInfo)}>
                {!isInternalUser && (
                    <>
                        {emailDialogOpen && (
                            <UpdateEmailModal open={emailDialogOpen} onClose={handleLeave} />
                        )}
                        <Button
                            variant='outlined'
                            disabled={emailDialogOpen}
                            sx={{
                                textTransform: 'unset',
                            }}
                            onClick={() => {
                                setEmailDialogOpen(true);
                            }}
                        >
                            {t('CHANGE_EMAIL')}
                        </Button>
                    </>
                )}
            </SettingField>
        </SettingsPanelBox>
    );
};

export default EmailField;
