import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Divider, Menu, MenuItem, Theme, useTheme } from '@mui/material';
import { CSSSelectorObjectOrCssVariables } from '@mui/system';
import { ForwardedRef, MutableRefObject, ReactNode } from 'react';
import { ThemeValues } from 'src/constants/theme-values';
import { useSwitcherContext } from 'src/contexts/SwitcherContext';
import { getDefaultSwitcherConfig } from './SwitcherProvider';

const switcherDefaults = getDefaultSwitcherConfig();

const getPaddingStyle = (hasCta: boolean, hasTitle: boolean) => {
    if (!hasCta && !hasTitle) {
        return {
            padding: '4px 0px',
        };
    }

    return {
        padding: `4px 0px ${hasCta ? 0 : '4px'}`,
    };
};

export const SwitcherMenu = ({
    menuTitle,
    ctaTitle,
    onCtaClick,
    anchorRef,
    menuOpen,
    children,
    onMenuClose,
    menuPaperSx = {},
}: {
    menuTitle?: string;
    ctaTitle?: string;
    onCtaClick?: () => void;
    anchorRef: MutableRefObject<HTMLButtonElement>;
    menuOpen: boolean;
    children: ReactNode;
    onMenuClose: () => void;
    menuPaperSx?: CSSSelectorObjectOrCssVariables<Theme>;
}) => {
    const { menuAnchorOrigin: defaultAnchorOrigin, menuTransformOrigin: defaultTransformOrigin } =
        switcherDefaults;
    const theme = useTheme<Theme>();
    const { menuAnchorOrigin, menuTransformOrigin } = useSwitcherContext();
    const hasCta = !!ctaTitle && !!onCtaClick;

    return (
        <Menu
            anchorEl={() => anchorRef.current}
            anchorOrigin={menuAnchorOrigin || defaultAnchorOrigin}
            transformOrigin={menuTransformOrigin || defaultTransformOrigin}
            keepMounted
            sx={{
                '.MuiMenu-paper': {
                    width: '286px',
                    maxHeight: '350px',
                    borderRadius: ThemeValues.BorderRadius,
                    ...menuPaperSx,
                },
            }}
            MenuListProps={{
                sx: {
                    ...getPaddingStyle(hasCta, !!menuTitle),
                },
            }}
            open={menuOpen}
            onClose={onMenuClose}
        >
            {menuTitle && (
                <MenuItem
                    aria-hidden={true}
                    disabled={true}
                    sx={{
                        padding: '12px 16px 8px 16px',
                        fontWeight: 500,
                        fontSize: theme.typography.body2.fontSize,
                        color: theme.palette.text.secondary,
                        '&.Mui-disabled': {
                            opacity: 1,
                        },
                    }}
                >
                    {menuTitle}
                </MenuItem>
            )}
            {children}
            {hasCta && [
                <Divider
                    role='separator'
                    key='cta_divider'
                    sx={{
                        '&.MuiDivider-root.MuiDivider-fullWidth': {
                            margin: 0,
                        },
                    }}
                />,
                <MenuItem
                    key='cta_button'
                    sx={{
                        padding: '16px',
                        fontWeight: 500,
                        fontSize: theme.typography.body2.fontSize,
                        color: theme.palette.primary.main,
                    }}
                    onClick={onCtaClick}
                >
                    {ctaTitle}
                    <ArrowForwardIcon sx={{ fontSize: '20px', marginLeft: '8px' }} />
                </MenuItem>,
            ]}
        </Menu>
    );
};
