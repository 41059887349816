import { Dispatch, SetStateAction, useEffect } from 'react';
import { useRccContext } from 'src/contexts';
import { useUserPreferencesContext } from 'src/contexts/UserPreferencesContext';
import { Organization } from 'src/types';
import { useGetOrganizationByName } from './organization.fetchers';

type PreloadFetcher = (id: number, preloadOptionId: number, setPreloadOptionId: Dispatch<SetStateAction<number>>, setOrganization: (org: Organization | null) => void, setHasPreloaded: Dispatch<SetStateAction<boolean>>, hasPreloaded: boolean, ...args: any) => void;

export const useGetPreloadSearchQuery: PreloadFetcher = (
    id,
    preloadOptionId,
    setPreloadOptionId,
    setOrganization,
    setHasPreloaded,
    hasPreloaded,
    searchParam: string,
) => {
    const { isAuthenticated } = useRccContext();
    const isReady = isAuthenticated && preloadOptionId == id && !hasPreloaded;
    const { organizationData, errorOrg } = useGetOrganizationByName(isReady ? searchParam : null);

    useEffect(() => {
        if (!isReady) return

        if (!searchParam || !!errorOrg) {
            setPreloadOptionId((id) => ++id);
            return;
        }

        if (!!organizationData) {
            setOrganization(organizationData);
            setHasPreloaded(true);
        }
    }, [errorOrg, isReady, searchParam, organizationData, setOrganization, setHasPreloaded, setPreloadOptionId])
}

export const useGetPreloadDefault: PreloadFetcher = (
    id,
    preloadOptionId,
    setPreloadOptionId,
    setOrganization,
    setHasPreloaded,
    hasPreloaded) => {
    const { isAuthenticated } = useRccContext();
    const { userPreferences, isUserPreferencesLoading } = useUserPreferencesContext();
    const isReady = isAuthenticated && preloadOptionId == id && !isUserPreferencesLoading && !hasPreloaded;
    const { organizationData, errorOrg } = useGetOrganizationByName(isReady ? userPreferences?.defaultOrganizationId : null);

    useEffect(() => {
        if (!isReady) return

        if ((userPreferences && !userPreferences.defaultOrganizationId) || !!errorOrg) {
            setPreloadOptionId((id) => ++id);
            return;
        }

        if (!!organizationData) {
            setOrganization(organizationData);
            setHasPreloaded(true);
        }
    }, [isReady, errorOrg, userPreferences, organizationData, setOrganization, setHasPreloaded, setPreloadOptionId]);
}

export const useGetPreloadFirstOrganization: PreloadFetcher = (
    id,
    preloadOptionId,
    setPreloadOptionId,
    setOrganization,
    setHasPreloaded,
    hasPreloaded,
    isLoadingEffectiveOrgs: boolean,
    userOrganizations: Organization[],
) => {
    const { isAuthenticated } = useRccContext();
    const isReady = isAuthenticated && preloadOptionId == id && !isLoadingEffectiveOrgs && !hasPreloaded;
    const firstOrg = userOrganizations?.length > 0 ? userOrganizations[0] : null;

    const { organizationData, errorOrg } = useGetOrganizationByName(isReady ? firstOrg?.organizationName : null);

    useEffect(() => {
        if (!isReady) return

        if (!!errorOrg || userOrganizations?.length == 0) {
            setPreloadOptionId((id) => ++id);
            setHasPreloaded(true);
            return;
        }

        if (!!organizationData) {
            setOrganization(organizationData);
            setHasPreloaded(true);
        }
    }, [isReady, errorOrg, organizationData, userOrganizations, setHasPreloaded, setOrganization, setPreloadOptionId]);
}
