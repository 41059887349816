import { AxiosRequestConfig } from 'axios';
import authClient from 'src/clients/axios-client';

export const get = <T>(key: string, opts: AxiosRequestConfig = {}): Promise<T> => {
    return authClient.get<T>(key, opts).then((d) => d.data);
};

export const put = <T, B = any>(key: string, data: B, opts: AxiosRequestConfig = {}): Promise<T> => {
    return authClient.put<T>(key, data, opts).then((d) => d.data);
}

export const deletef = <T>(key: string, opts: AxiosRequestConfig = {}): Promise<T> => {
    return authClient.delete<T>(key, opts).then((d) => d.data);
}

export const post = <T, B = any>(key: string, data?: B, opts: AxiosRequestConfig = {}): Promise<T> => {
    return authClient.post<T>(key, data, opts).then((d) => d.data);
}