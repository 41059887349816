import {
    Button,
    DialogContent,
    DialogTitle,
    Stack,
    TextField,
    Typography,
    Tooltip,
} from '@mui/material';
import { CommonDialog } from 'src/components/common/Dialog/CommonDialog';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { FC, useState } from 'react';

interface SettingPopupInputProps {
    label: string;
    prompt: string;
    value: string;
    onChange: (value: string) => void;
    onSave: () => void;
}

const SettingPopupInput: FC<SettingPopupInputProps> = ({
    label,
    prompt,
    value,
    onChange,
    onSave,
}) => {
    const { t: commonTranslation } = useTranslationNs();
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

    const handleSave = () => {
        onSave();
        setIsDialogOpen(false);
    };

    return (
        <>
            <Tooltip title={value}>
                <TextField
                    disabled
                    value={value}
                    InputProps={{
                        endAdornment: (
                            <Button
                                onClick={() => setIsDialogOpen(true)}
                                sx={{
                                    textTransform: 'unset',
                                }}
                            >
                                {commonTranslation('COMMON.BUTTONS.CHANGE')}
                            </Button>
                        ),
                    }}
                />
            </Tooltip>
            <CommonDialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
                <DialogTitle
                    sx={{
                        fontWeight: '600',
                        fontSize: '20px',
                    }}
                >
                    {prompt}
                </DialogTitle>
                <DialogContent
                    sx={{
                        width: '460px',
                    }}
                >
                    <Stack gap={2} padding='8px'>
                        <TextField
                            fullWidth
                            value={value}
                            onChange={(e) => onChange(e.target.value)}
                            label={label}
                        />
                        <Button
                            onClick={handleSave}
                            variant='contained'
                            sx={{
                                textTransform: 'unset',
                            }}
                        >
                            {commonTranslation('COMMON.BUTTONS.SAVE')}
                        </Button>
                    </Stack>
                </DialogContent>
            </CommonDialog>
        </>
    );
};

export default SettingPopupInput;
