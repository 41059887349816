import { AppConstants } from 'src/constants/app';
import { DefaultErrorHandler } from 'src/utils/services.utils';
import { ResetPasswordDto, UserProfile } from '..';
import { FrontDoorProxyUrl } from '../constants/global';
import { get, post, put } from './common.fetchers';

const updateUserPassword = (resetPasswordDto: ResetPasswordDto): Promise<null> => {
    return post<null, ResetPasswordDto>(
        `${FrontDoorProxyUrl()}/users/change-my-password`,
        resetPasswordDto,
        {
            headers: {
                [AppConstants.OrgHeader]: null,
            },
        },
    ).catch(DefaultErrorHandler);
};

const updateUserProfileSelf = async (
    data: UserProfile,
    organization: string,
): Promise<UserProfile> => {
    return put<null, UserProfile>(`${FrontDoorProxyUrl()}/provisioning/user-profiles`, data, {
        withCredentials: true,
        headers: {
            [AppConstants.OrgHeader]: organization,
        },
    });
};

const getUserProfileSelf = async (organization: string): Promise<UserProfile> => {
    return get<UserProfile>(`${FrontDoorProxyUrl()}/provisioning/user-profiles`, {
        withCredentials: true,
        headers: {
            [AppConstants.OrgHeader]: organization,
        },
    });
};

export { updateUserPassword, updateUserProfileSelf, getUserProfileSelf };
