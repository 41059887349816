import { MenuItem, Typography } from '@mui/material';
import { useState } from 'react';
import { SettingInputLabel } from 'src/components/user-settings/common/SettingInputLabel';
import { SettingsSwitcher } from 'src/components/user-settings/common/SettingsSwitcher';
import { ThemeValues } from 'src/constants/theme-values';
import { useUserPreferencesContextInternal } from 'src/contexts/UserPreferencesContext';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { SelectOption } from 'src/types/input.type';
import { UserPreferencesTheme } from 'src/types/user-preferences.type';

const options: SelectOption<UserPreferencesTheme>[] = [
    { value: UserPreferencesTheme.SYSTEM, viewValue: 'System' },
    { value: UserPreferencesTheme.DARK, viewValue: 'Dark' },
    { value: UserPreferencesTheme.LIGHT, viewValue: 'Light' },
];

const ThemePreferenceSwitcherList = ({
    selectedTheme,
    onOptionClick,
}: {
    selectedTheme: UserPreferencesTheme;
    onOptionClick: (orgName: string) => void;
}) => {
    return options?.map((option: SelectOption<UserPreferencesTheme>) => {
        const val = option.value;
        return (
            <MenuItem
                sx={{
                    padding: ThemeValues.MenuItemPadding,
                }}
                selected={val === selectedTheme}
                key={val}
                onClick={() => onOptionClick(val)}
            >
                <Typography variant='subtitle2'>{option.viewValue}</Typography>
            </MenuItem>
        );
    });
};

export const ThemePreferences = () => {
    const {
        userPreferences,
        isUserPreferencesLoading,
        isUserPreferencesValidating,
        isUserPreferencesMutating,
        updateUserPreferences,
    } = useUserPreferencesContextInternal();
    const { t } = useTranslationNs({
        keyPrefix: 'USER_SETTINGS.PREFERENCES.DISPLAY.THEME',
    });
    const isLoading =
        isUserPreferencesValidating || isUserPreferencesMutating || isUserPreferencesLoading;
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const handleOpenMenu = () => {
        setIsMenuOpen(true);
    };

    const handleCloseMenu = () => {
        setIsMenuOpen(false);
    };

    const handleOptionClick = async (val: UserPreferencesTheme) => {
        setIsMenuOpen(false);
        await updateUserPreferences({ theme: val });
    };

    const selectedOption: SelectOption<UserPreferencesTheme> = options.find(
        (option) => option.value === userPreferences?.theme,
    );

    return (
        <SettingInputLabel title={t('TITLE')} description={t('DESCRIPTION')}>
            <SettingsSwitcher
                tooltipTitle={t('DESCRIPTION')}
                disabled={isLoading}
                isLoading={isLoading}
                onClick={handleOpenMenu}
                isMenuOpen={isMenuOpen}
                onMenuClose={handleCloseMenu}
                menuContent={
                    <ThemePreferenceSwitcherList
                        selectedTheme={userPreferences?.theme}
                        onOptionClick={handleOptionClick}
                    />
                }
            >
                {selectedOption?.viewValue}
            </SettingsSwitcher>
        </SettingInputLabel>
    );
};
