import { useEffect, useRef } from 'react';
import { useSWRConfig } from 'swr';
import { removeRccLocalStorage } from 'src/utils/local-storage.utils';
import { useOktaAuth } from '@okta/okta-react';
import { useClearNonRccCachesOnLogout } from 'src/hooks/useClearNonRccCachesOnLogout';
import { GlobalEmitter } from 'src/constants/global';
import { RccEventKeys } from 'src/types';

export const RccCacheOperator = ({ enableAutoLogout }: { enableAutoLogout: boolean }) => {
    const { cache } = useSWRConfig();
    const { oktaAuth, authState } = useOktaAuth();
    const clearCaches = useClearNonRccCachesOnLogout();
    const hasAlreadyLoggedOut = useRef<boolean>(false);

    useEffect(
        function clearUnauthenticatedCache() {
            if (authState?.isAuthenticated === false) {
                removeRccLocalStorage();

                for (let key of cache.keys()) {
                    cache.delete(key);
                }
            }
        },
        [authState?.isAuthenticated],
    );

    useEffect(
        function autoLogout() {
            const enabled = enableAutoLogout ?? true;

            /**
             *
             * It appears the "expired" event is emitted
             * around 4 times.
             *
             * Add a check to prevent unnecessary clear
             * calls.
             */
            const onExpire = async () => {
                if (hasAlreadyLoggedOut.current) return;

                hasAlreadyLoggedOut.current = true;
                GlobalEmitter.emit(RccEventKeys.SIGNOUT);
                clearCaches();

                if (enabled) {
                    await oktaAuth.signOut();
                }
            };

            oktaAuth.tokenManager.on('expired', onExpire);

            return () => {
                oktaAuth.tokenManager.off('expired', onExpire);
            };
        },
        [oktaAuth.tokenManager, clearCaches, enableAutoLogout],
    );

    return null;
};
