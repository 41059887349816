import { SettingsTabContainer } from 'src/components/user-settings/common/SettingContainer';
import ProfileForm from 'src/components/user-settings/profile/info/PersonalInfo/ProfileForm/ProfileForm';

export const UserProfileV2 = () => {
    return (
        <SettingsTabContainer>
            <ProfileForm />
        </SettingsTabContainer>
    );
};
