import Stack from '@mui/material/Stack';
import BasicInfoForm from './BasicInfoForm';
import AddressForm from './AddressForm';
import Skeleton from '@mui/material/Skeleton';
import EmailField from 'src/components/user-settings/profile/info/Email/EmailField';
import { useUserProfileContext } from 'src/contexts/UserProfileContext';

const ProfileForm = () => {
    const { userProfileLoading } = useUserProfileContext();

    if (userProfileLoading) {
        return <ProfileSkeleton />;
    }

    return (
        <Stack gap={4}>
            <BasicInfoForm />
            <EmailField />
            <AddressForm />
        </Stack>
    );
};

const ProfileSkeleton = () => {
    return (
        <Stack gap={2}>
            <Skeleton height={160} variant='rounded' />
            <Skeleton height={160} variant='rounded' />
            <Skeleton height={160} variant='rounded' />
        </Stack>
    );
};

export default ProfileForm;
