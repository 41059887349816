import LanRoundedIcon from '@mui/icons-material/LanRounded';
import { MenuItem, Typography } from '@mui/material';
import { ReactNode, useState } from 'react';
import { SettingsSwitcher } from 'src/components/user-settings/common/SettingsSwitcher';
import { ThemeValues } from 'src/constants/theme-values';
import { useOrganizationContext } from 'src/contexts';
import { useUserPreferencesContextInternal } from 'src/contexts/UserPreferencesContext';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { Organization } from 'src/types';

const OrganizationPreferenceSwitcherList = ({
    userOrganizations,
    defaultOrg,
    handleOrgClick,
}: {
    userOrganizations: Organization[];
    defaultOrg: string;
    handleOrgClick: (orgName: string) => void;
}) => {
    return userOrganizations?.map((org: Organization) => {
        const orgName = org.organizationName;

        return (
            <MenuItem
                sx={{
                    padding: ThemeValues.MenuItemPadding,
                }}
                selected={orgName === defaultOrg}
                key={orgName}
                onClick={() => handleOrgClick(orgName)}
            >
                <Typography variant='subtitle2'>{orgName}</Typography>
            </MenuItem>
        );
    });
};

export const OrganizationPreferenceSwitcher = () => {
    const { t } = useTranslationNs();
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const { isLoading, userOrganizations } = useOrganizationContext();
    const {
        userPreferences,
        isUserPreferencesLoading,
        isUserPreferencesValidating,
        isUserPreferencesMutating,
        updateUserPreferences,
    } = useUserPreferencesContextInternal();
    const hasNoOrgs = !isLoading && userOrganizations.length === 0;

    const handleOpenOrgMenu = () => {
        setIsMenuOpen(true);
    };

    const handleCloseOrgMenu = () => {
        setIsMenuOpen(false);
    };

    const handleOrgClick = async (orgName: string) => {
        setIsMenuOpen(false);
        await updateUserPreferences({ defaultOrganizationId: orgName });
    };

    let switcherLabel: ReactNode;
    if (hasNoOrgs) {
        switcherLabel = t('ORG_CONTEXT.SWITCHER.EMPTY');
    } else if (isLoading) {
        switcherLabel = t('COMMON.LOADING');
    } else {
        switcherLabel =
            userPreferences?.defaultOrganizationId ||
            t('USER_SETTINGS.PREFERENCES.DEFAULTS.ORGANIZATION.EMPTY');
    }

    return (
        <SettingsSwitcher
            tooltipTitle={t('USER_SETTINGS.PREFERENCES.DEFAULTS.ORGANIZATION.TOOLTIP')}
            disabled={
                userOrganizations?.length <= 1 ||
                isUserPreferencesValidating ||
                isUserPreferencesMutating
            }
            enableSwitcher={userOrganizations?.length !== 1}
            isLoading={isLoading || isUserPreferencesLoading}
            onClick={handleOpenOrgMenu}
            startIcon={<LanRoundedIcon fontSize='small' />}
            isMenuOpen={isMenuOpen}
            onMenuClose={handleCloseOrgMenu}
            menuContent={
                <OrganizationPreferenceSwitcherList
                    handleOrgClick={handleOrgClick}
                    userOrganizations={userOrganizations}
                    defaultOrg={userPreferences?.defaultOrganizationId}
                />
            }
        >
            {switcherLabel}
        </SettingsSwitcher>
    );
};
