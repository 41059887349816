import { useMemo } from 'react';
import { countries } from 'countries-list';
import _ from 'lodash';

const useCountryOptions = () => {
    return useMemo(() => {
        return _.sortBy(Object.values(countries), 'name');
    }, []);
};

export default useCountryOptions;
