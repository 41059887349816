import { Box, Typography } from "@mui/material";
import { useOktaAuth } from "@okta/okta-react";
import { Navigate } from "react-router-dom";

export const LandingPage = () => {
  const { authState } = useOktaAuth();

  if (authState && authState.isAuthenticated) {
    return <Navigate to="/app/home" replace={true} />;
  }

  return (
    <Box>
      <Typography variant="h5" maxWidth={"400px"}>
        Welcome to the React Common Component Reference App!
      </Typography>
    </Box>
  );
};
