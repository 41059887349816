import { Box, Divider, Stack, Typography } from '@mui/material';
import { cloneElement, Dispatch, ReactElement, ReactNode, SetStateAction } from 'react';
import * as React from 'react';
import EditButton from 'src/components/common/EditButton/EditButton';
import {
    SaveCancelButton,
    SaveCancelProps,
} from 'src/components/common/SaveCancelButton/SaveCancelButton';

export type SettingsPanelProps = {
    children: ReactNode;
    title: string;
    icon?: ReactElement;
    readonly?: boolean;
    disableEditMode?: boolean;
};

export const SettingsPanel = ({
    children,
    title,
    icon,
    editMode = false,
    setEditMode,
    readonly = false,
    disableEditMode = false,
    ...rest
}: { editMode?: boolean; setEditMode?: Dispatch<SetStateAction<boolean>> } & SettingsPanelProps &
    SaveCancelProps) => {
    const buttonsProps: Pick<SaveCancelProps, keyof SaveCancelProps> = {
        ...rest,
    };

    return (
        <Box border='1px solid' borderColor='divider' borderRadius='12px'>
            <Box
                display='flex'
                justifyContent='space-between'
                p='16px'
                alignItems='center'
                id='settings-panel-header'
            >
                <Box display='flex' justifyContent='flex-start' gap='8px' alignItems='center'>
                    {icon && (
                        <Box
                            sx={{
                                backgroundColor: 'background.default',
                                border: (theme) => `1px solid ${theme.palette.divider}`,
                                width: '32px',
                                height: '32px',
                                borderRadius: '8px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            {cloneElement(icon, {
                                sx: { width: 16, height: 16 },
                            })}
                        </Box>
                    )}
                    <Typography variant='h5' fontWeight={600} fontSize='inherit'>
                        {title}
                    </Typography>
                </Box>
                {!editMode && !readonly && (
                    <EditButton
                        buttonVariant='icon'
                        onClick={() => {
                            setEditMode(true);
                        }}
                    />
                )}
            </Box>
            <Divider
                style={{
                    width: '100%',
                }}
            />
            <Stack p='16px' direction='column' gap={2} id='settings-panel-body'>
                {children}
                {editMode && <SaveCancelButton fullWidth {...buttonsProps} />}
            </Stack>
        </Box>
    );
};

export const SettingsPanelBox = ({ children, title, icon }: SettingsPanelProps) => {
    return (
        <Box border='1px solid' borderColor='divider' borderRadius='12px'>
            <Box
                display='flex'
                justifyContent='space-between'
                p='16px'
                alignItems='center'
                id='settings-panel-header'
            >
                <Box display='flex' justifyContent='flex-start' gap='8px' alignItems='center'>
                    {icon && (
                        <Box
                            sx={{
                                backgroundColor: 'background.default',
                                border: (theme) => `1px solid ${theme.palette.divider}`,
                                width: '32px',
                                height: '32px',
                                borderRadius: '8px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            {cloneElement(icon, {
                                sx: { width: 16, height: 16 },
                            })}
                        </Box>
                    )}
                    <Typography variant='h5' fontWeight={600} fontSize='inherit'>
                        {title}
                    </Typography>
                </Box>
            </Box>
            <Divider
                style={{
                    width: '100%',
                }}
            />
            <Stack p='16px' direction='column' gap={2} id='settings-panel-body'>
                {children}
            </Stack>
        </Box>
    );
};
