import { Box } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { OrganizationCheckerInput } from 'src/components/organization/OrganizationCheckerInput/OrganizationCheckerInput';
import { useUserPreferencesContextInternal } from 'src/contexts/UserPreferencesContext';
import { Organization } from 'src/types';

export const SearchOrganizationPreferenceSwitcher = () => {
    const isInitialLoad = useRef(true);
    const inputRef = useRef<HTMLInputElement>();
    const [searchTerm, setSearchTerm] = useState<string>();
    const [activeTerm, setActiveTerm] = useState<string>();
    const {
        userPreferences,
        isUserPreferencesLoading,
        isUserPreferencesValidating,
        isUserPreferencesMutating,
        updateUserPreferences,
    } = useUserPreferencesContextInternal();

    useEffect(() => {
        if (isInitialLoad.current) {
            const defaultOrg = userPreferences?.defaultOrganizationId || '';
            setSearchTerm(defaultOrg);
            setActiveTerm(defaultOrg);
            inputRef.current.value = defaultOrg;
            isInitialLoad.current = false;
        }
    }, [userPreferences?.defaultOrganizationId]);

    const ctaClickHandler = async (organization: Organization) => {
        await updateUserPreferences({ defaultOrganizationId: organization.organizationName });
    };

    return (
        <Box width='100%'>
            <OrganizationCheckerInput
                ref={inputRef}
                onCtaClick={ctaClickHandler}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
                activeTerm={activeTerm}
                setActiveTerm={setActiveTerm}
                isDisabled={
                    isUserPreferencesMutating ||
                    isUserPreferencesValidating ||
                    isUserPreferencesLoading
                }
            />
        </Box>
    );
};
