import { Person } from '@mui/icons-material';
import { Button } from '@mui/material';
import { Fragment, MutableRefObject, useRef, useState } from 'react';
import useMobileView from 'src/hooks/useMobileView';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { UserProfileOption } from 'src/types/user-profile.type';
import { ProfileIcon } from '../common/ProfileIcon/ProfileIcon';
import { UserProfileSettingsDrawer } from './UserProfileSettingsDrawer';
import { UserProfileSettingsMenu } from './UserProfileSettingsMenu';
import { UserSettingsModal } from '../user-settings/UserSettingsModal';
import { useUserProfileContext } from 'src/contexts/UserProfileContext';

export const UserProfileSettings = ({
    inputOptions = [],
}: {
    inputOptions?: UserProfileOption[];
}) => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const [isUserSettingsModalOpen, setIsUserSettingsModalOpen] = useState(false);
    const { t } = useTranslationNs({
        keyPrefix: 'USER_PROFILE',
    });
    const isMobile = useMobileView();
    const buttonRef: MutableRefObject<HTMLButtonElement | null> = useRef(null);
    const { primaryUserProfile } = useUserProfileContext();

    const baseSettingsOptions = [
        {
            icon: <Person color='inherit' fontSize='medium' />,
            label: t('ACCOUNT'),
            onClick: () => {
                setIsUserSettingsModalOpen(true);
            },
        },
    ];

    const navigationSettingOptions: UserProfileOption[] = [
        ...baseSettingsOptions,
        ...inputOptions,
    ].filter((option: UserProfileOption) => !option.hidden);

    const handleMenuOpen = () => {
        setIsMenuOpen(true);
    };
    const handleMenuClose = () => {
        setIsMenuOpen(false);
    };
    const handleUserSettingsModalClose = () => {
        setIsUserSettingsModalOpen(false);
    };

    return (
        <Fragment>
            <Button
                onClick={handleMenuOpen}
                ref={buttonRef}
                type='button'
                sx={{ borderRadius: '50%', padding: '8px', minWidth: 'unset' }}
                data-element-id='user-menu-nav-button'
            >
                <ProfileIcon profileInfo={primaryUserProfile} />
            </Button>
            {isMobile ? (
                <UserProfileSettingsDrawer
                    menuOpen={isMenuOpen}
                    options={navigationSettingOptions}
                    onMenuOpen={handleMenuOpen}
                    onMenuClose={handleMenuClose}
                />
            ) : (
                <UserProfileSettingsMenu
                    options={navigationSettingOptions}
                    anchorRef={buttonRef}
                    menuOpen={isMenuOpen}
                    onMenuClose={handleMenuClose}
                />
            )}
            <UserSettingsModal
                onClose={handleUserSettingsModalClose}
                isOpen={isUserSettingsModalOpen}
            />
        </Fragment>
    );
};
