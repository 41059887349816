import { PropsWithChildren, RefObject, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';

const ResistanceRate = 4;
const MaxPullLength = 250;

const useTouchScrollBounce = (ref: RefObject<HTMLDivElement>) => {
    const startTouchPoint = useRef<number>(0);
    const [pullTouchChange, setPullTouchChange] = useState<number>();
    const [direction, setDirection] = useState<'top' | 'bottom' | null>(null);

    useEffect(() => {
        const pullStart = (e) => {
            const { screenY } = e.targetTouches[0];
            startTouchPoint.current = screenY;
        };

        const pull = (e) => {
            const touch = e.targetTouches[0];
            const { screenY } = touch;
            let pullLength = screenY - startTouchPoint.current;

            if (pullLength > 0) {
                setDirection('top');
            } else if (pullLength < 0) {
                setDirection('bottom');
            }

            setPullTouchChange(Math.min(Math.abs(pullLength), MaxPullLength));
        };

        const endPull = (e) => {
            startTouchPoint.current = 0;
            setPullTouchChange(0);
            setDirection(null);
        };

        window.addEventListener('touchstart', pullStart);
        window.addEventListener('touchmove', pull);
        window.addEventListener('touchend', endPull);

        return () => {
            window.removeEventListener('touchstart', pullStart);
            window.removeEventListener('touchmove', pull);
            window.removeEventListener('touchend', endPull);
        };
    }, []);

    useEffect(() => {
        if (ref.current) {
            if (direction === 'top') {
                ref.current.style.marginTop = `${pullTouchChange / ResistanceRate}px`;
            } else if (direction === 'bottom') {
                ref.current.style.marginBottom = `${pullTouchChange / ResistanceRate}px`;
            } else {
                ref.current.style.marginTop = '0';
                ref.current.style.marginBottom = '0';
            }
        }
    }, [pullTouchChange, direction]);

    return null;
};

const BouncyScrollView = ({ children }: PropsWithChildren) => {
    const containerRef = useRef<HTMLDivElement>(null);
    useTouchScrollBounce(containerRef);

    return <Box ref={containerRef}>{children}</Box>;
};

export default BouncyScrollView;
