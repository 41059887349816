import { useCallback } from 'react';
import { AppConstants } from 'src/constants/app';
import { useOrganizationsCacheContextInternal } from 'src/contexts/OrganizationsCacheContext';

export const useClearNonRccCachesOnLogout = () => {
    const { deleteEffectiveOrgsCache } = useOrganizationsCacheContextInternal();

    const clearCaches = useCallback(() => {
        // Clear Apps List caches
        localStorage.removeItem(AppConstants.ApplicationsListCacheKey);

        // Clear Firestore effective organizations cache
        deleteEffectiveOrgsCache().catch(() => {
            /**
             * Logout can fail in the case expiration event happens
             * and the user is not authenticated.
             *
             * In that case effective organizations, cache should
             * be cleared when the user manually logs out, or waits
             * for the original token expiration timestamp.
             */
        });
    }, [deleteEffectiveOrgsCache]);

    return clearCaches;
};
