export const AppConstants = {
    OrgMenuMax: 5,
    AppMenuMax: 10,
    EffectiveOrgsLimit: 30,
    NcrEmailFormat: '@ncr.com',
    NcrVoyixEmailFormat: '@ncrvoyix.com',
    i18NS: 'rcc',
    OrgHeader: 'nep-organization',
    GlobalConfigSetName: 'v1_home_global_settings',
    MonthDayYearFormat: 'MM/DD/YYYY',
    YearMonthDayFormat: 'YYYY-MM-DD',
    ApplicationsListCacheKey: 'rcc-applications-list-cache',
    RccCacheKey: 'rcc-app-cache',
};
