import { Person } from '@mui/icons-material';
import { Avatar, SxProps, useTheme } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import Skeleton from '@mui/material/Skeleton';

export type ProfileIconSize = 'tablet' | 'laptop';

type ProfileIconSizeConfig = {
    size: string;
    fontSize: string;
};

type ProfileInfo = {
    givenName: string;
    familyName: string;
};

type IProfileIconSizes = Record<ProfileIconSize, ProfileIconSizeConfig>;

const ProfileIconSizes: IProfileIconSizes = {
    tablet: {
        size: '32px',
        fontSize: '12px',
    },
    laptop: {
        size: '40px',
        fontSize: '16px',
    },
};

export const ProfileIcon = ({
    size = 'tablet',
    profileInfo,
}: {
    size?: ProfileIconSize;
    profileInfo?: ProfileInfo;
}) => {
    const [initials, setInitials] = useState<string>('');
    const sizeConfig: ProfileIconSizeConfig = ProfileIconSizes[size];
    const theme = useTheme();

    useEffect(() => {
        if (profileInfo) {
            const first = (profileInfo.givenName && profileInfo.givenName[0]) || '';
            const last = (profileInfo.familyName && profileInfo.familyName[0]) || '';
            setInitials(`${first.toUpperCase()}${last.toUpperCase()}`);
        } else {
            setInitials('');
        }
    }, [profileInfo]);

    const sxProps: SxProps = {
        height: sizeConfig.size,
        width: sizeConfig.size,
        fontSize: sizeConfig.fontSize,
        backgroundColor: theme.palette.grey[600],
        color: theme.palette.common.white,
        lineHeight: 1,
        paddingTop: '.09em',
    };

    if (!profileInfo) {
        return <Skeleton variant='circular' sx={sxProps} />;
    }

    return (
        <Fragment>
            {Boolean(initials) ? (
                <Avatar sx={sxProps}>{initials}</Avatar>
            ) : (
                <Avatar sx={sxProps}>
                    <Person fontSize='medium' />
                </Avatar>
            )}
        </Fragment>
    );
};
