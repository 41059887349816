import { createContext, useContext } from 'react';
import { UpdateUserPreferences, UserPreferences } from 'src/types/user-preferences.type';
import { ApiError } from '..';

export type IUserPreferencesContext = {
    userPreferences: UserPreferences;
    isUserPreferencesLoading: boolean;
    isUserPreferencesMutating: boolean;
    isUserPreferencesValidating: boolean;
    userPreferencesMutationError: ApiError;
    getUserPreferencesError: ApiError;
    updateUserPreferences: (preferences: UpdateUserPreferences) => Promise<void>;
};

export const UserPreferencesContext = createContext<IUserPreferencesContext>(
    {} as IUserPreferencesContext,
);

export const useUserPreferencesContextInternal = () => useContext(UserPreferencesContext);

export const useUserPreferencesContext = () => {
    const {
        userPreferences,
        isUserPreferencesLoading,
        isUserPreferencesMutating,
        userPreferencesMutationError,
        getUserPreferencesError,
    } = useContext(UserPreferencesContext);

    return {
        userPreferences,
        isUserPreferencesLoading,
        isUserPreferencesMutating,
        userPreferencesMutationError,
        getUserPreferencesError,
    };
};
