import { TFunction } from 'i18next';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { HomeAppName } from 'src/constants/global';
import { useOrganizationContext, useRccContext } from 'src/contexts';
import {
    ApplicationsContext,
    IApplicationsContextInternal,
} from 'src/contexts/ApplicationsContext';
import {
    useGetAllApplications,
    useGetApplicationsForUser,
} from 'src/fetchers/applications.fetchers';
import { useTranslationNs } from 'src/hooks/useTranslationNs';
import { ApiError } from 'src/types';
import { ApplicationListItemModel, SharedApplicationModel } from 'src/types/application.type';

export const convertAppToShared = (app: ApplicationListItemModel): SharedApplicationModel => ({
    name: app.name,
    displayName: app.displayName,
    baseUrl: app.baseUrl,
    redirectUrl: app.redirectUrl,
    imageUrl: app.imageUrl,
});

const findHomeApp = <T extends SharedApplicationModel>(apps: T[]): T | undefined => {
    return apps.find((a) => a.name === HomeAppName);
};

const findMatchingApp = <T extends SharedApplicationModel>(
    apps: T[],
    t: TFunction<[string, string]>,
    defaultApp?: string,
): T => {
    let app: T | undefined;

    if (defaultApp) {
        app = apps.find((am) => am.name === defaultApp);
    }

    if (!app) {
        const currentHost = window.location.hostname;
        app = apps.find((am) => am.baseUrl.includes(currentHost));
    }

    if (!app) console.warn(t('WARNINGS.NO_DEFAULT_APPLICATION'));

    return app;
};

const coalesceArr = <T extends any[]>(arr?: T): T => {
    return !!arr ? arr : ([] as T);
};

export const ApplicationsContextProvider = ({
    defaultApp,
    children,
}: {
    defaultApp?: string;
    children: ReactNode;
}) => {
    const { t } = useTranslationNs();
    const { isAuthenticated } = useRccContext();
    const { organization } = useOrganizationContext();
    const [isAppDialogOpen, setIsAppDialogOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<ApiError>();
    const [application, setApplication] = useState<SharedApplicationModel | undefined>();
    const {
        data: userApplications,
        isLoading: isLoadingUserApps,
        error: userAppsError,
    } = useGetApplicationsForUser(organization);
    const {
        data: allApplications,
        isLoading: isLoadingAllApps,
        error: allAppsError,
    } = useGetAllApplications();

    const getSwitcherList = useCallback((): ApplicationListItemModel[] => {
        return isAuthenticated ? coalesceArr(userApplications) : coalesceArr(allApplications);
    }, [isAuthenticated, allApplications, userApplications]);

    const getDefaultAppFromList = useCallback(
        (list: ApplicationListItemModel[]): SharedApplicationModel => {
            const matchingApp = findMatchingApp(list, t, defaultApp);
            const homeApp = findHomeApp(list);
            const resultingApp = matchingApp ?? homeApp ?? list[0];
            return convertAppToShared(resultingApp);
        },
        [t, defaultApp],
    );

    useEffect(
        function setActiveApplication() {
            if (!!userApplications) {
                setApplication(getDefaultAppFromList(userApplications));
            } else if (!!allApplications) {
                setApplication(getDefaultAppFromList(allApplications));
            }
        },
        [getDefaultAppFromList, userApplications, allApplications, setApplication],
    );

    useEffect(
        function syncLoading() {
            if (error) {
                setIsLoading(false);
                return;
            }

            setIsLoading(isLoadingUserApps || isLoadingAllApps);
        },
        [isLoadingAllApps, isLoadingUserApps, error, setIsLoading],
    );

    useEffect(
        function syncError() {
            setError(userAppsError || allAppsError);
        },
        [setError, userAppsError, allAppsError],
    );

    useEffect(
        function detectNoApplications() {
            if (
                !isLoading &&
                !!userApplications &&
                userApplications?.length === 0 &&
                !!organization?.organizationName
            ) {
                console.warn(t('WARNINGS.NO_APPLICATIONS', { org: organization.organizationName }));
            }
        },
        [userApplications, organization, isLoading, t],
    );

    const contextValue: IApplicationsContextInternal = useMemo(
        () => ({
            isAppDialogOpen,
            setIsAppDialogOpen,
            getSwitcherList,
            application,
            userApplications: coalesceArr(userApplications),
            allApplications: coalesceArr(allApplications),
            isLoading,
            error,
        }),
        [
            application,
            userApplications,
            isLoading,
            error,
            isAppDialogOpen,
            setIsAppDialogOpen,
            getSwitcherList,
        ],
    );

    return (
        <ApplicationsContext.Provider value={contextValue}>{children}</ApplicationsContext.Provider>
    );
};
